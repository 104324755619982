import { RegistereRibbonButton, SimpleDialog, useAppInfo, useModelDrivenApp, useRibbon } from "@eavfw/apps";
import { CompoundButton, Input, Field } from "@fluentui/react-components";
import { useBoolean } from "@fluentui/react-hooks";
import { PlugDisconnected24Regular } from "@fluentui/react-icons";
import { useCallback, useState } from "react";

RegistereRibbonButton("CUSTOM_RESET_PASSWORD", ({ key, ...props }) => {
    const { registerButton } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();

    const [password,setPassword] = useState<string>();
    const app = useModelDrivenApp();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const connect = useCallback(() => {
         
        let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/${app.getEntity(currentEntityName).collectionSchemaName}/records/${currentRecordId}/workflows/SetPasswordWorkFlow/runs`, {
            method: "POST",
            body: JSON.stringify({
                "password": password
            }),
            credentials: "include"
        });
    }, [password]);

    registerButton({
        key: key,
        text: props.text ?? "Reset Password",
        iconProps: props.iconProps ?? { iconName: 'PasswordField' },
        title: props.title ?? props.text ?? "Reset Password",
        disabled: false,
        onClick: () => {
            toggleHideDialog();
          
        }
        //workflow: props.workflow
        // onClick: onClick
    });


    return <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>
        <Field hint="The new password" label="The new password" ><Input onChange={(ev, v) => setPassword(v.value)} /></Field>
        <CompoundButton
            icon={<PlugDisconnected24Regular />}
            secondaryContent="The password will be ashed in the database and not stored" onClick={connect}
        >
            Reset
        </CompoundButton>

    </SimpleDialog>

});