import { InfoLabel, InfoLabelProps, Link } from "@fluentui/react-components";
import { ReactNode } from "react";

export const ViewHeaderBox = ({ label, children, ...props }: { label: string, children: InfoLabelProps["info"] } & Partial<InfoLabelProps>) => (
  <InfoLabel
        info={
            children
    }
    size="large" weight="semibold"
    {...props}
    >
        {label}
  </InfoLabel>
);