
import { RegistereControl, useSectionStyles } from "@eavfw/apps"

import {
    AppItem,
    Hamburger,
    NavCategory,
    NavCategoryItem,
    NavDivider,
    NavDrawer,
    NavDrawerBody,
    NavDrawerHeader,
    NavDrawerProps,
    NavItem,
    NavSectionHeader,
    NavSubItem,
    NavSubItemGroup,
} from "@fluentui/react-nav-preview";


import {
    DrawerProps,
    Label,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    makeStyles,
    mergeClasses,
    tokens,
    useId,
} from "@fluentui/react-components";
import {
    Board20Filled,
    Board20Regular,
    BoxMultiple20Filled,
    BoxMultiple20Regular,
    DataArea20Filled,
    DataArea20Regular,
    DocumentBulletListMultiple20Filled,
    DocumentBulletListMultiple20Regular,
    HeartPulse20Filled,
    HeartPulse20Regular,
    MegaphoneLoud20Filled,
    MegaphoneLoud20Regular,
    NotePin20Filled,
    NotePin20Regular,
    People20Filled,
    People20Regular,
    PeopleStar20Filled,
    PeopleStar20Regular,
    Person20Filled,
    PersonLightbulb20Filled,
    PersonLightbulb20Regular,
    Person20Regular,
    PersonSearch20Filled,
    PersonSearch20Regular,
    PreviewLink20Filled,
    PreviewLink20Regular,
    bundleIcon,
    PersonCircle32Regular,
} from "@fluentui/react-icons";

import { Tree, TreeItem, TreeItemLayout } from "@fluentui/react-components";
import { useState } from "react";
import { useDocument } from "./Hooks/useDocument";
import { CodeIcon } from "./Icons/CodeIcon";
import { CustomerManifestDefinitionProvider, useCustomerManifest } from "./Contexts/CustomerManifestDefinitionProvider";
import { ManifestSourceView } from "./Views/ManifestSourceView";
import { AppIcon } from "./Icons/AppIcon";
import { ModuleIcon } from "./Icons/ModuleIcon";
import { TableIcon } from "./Icons/TableIcon";
import { FormIcon } from "./Icons/FormIcon";
import { MergeIcon } from "./Icons/MergeIcon";
import { AttributeIcon } from "./Icons/AttributeIcon";
import { ViewIcon } from "./Icons/ViewIcon";
import { TablesView } from "./Views/TablesView";

const useStyles = makeStyles({
    root: {
        overflow: "hidden",
        display: "flex",
     //   height: "100%",
        width: "100%",
        padding: "0",
      //  margin:"0",
    },
    content: {
     //   flex: "1",
       // padding: "16px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        flexGrow:1,
    },
    field: {
        display: "flex",
        marginTop: "4px",
        marginLeft: "8px",
        flexDirection: "column",
        gridRowGap: tokens.spacingVerticalS,
    },
});

const Person = bundleIcon(Person20Filled, Person20Regular);
const Dashboard = bundleIcon(Board20Filled, Board20Regular);
const Announcements = bundleIcon(MegaphoneLoud20Filled, MegaphoneLoud20Regular);
const EmployeeSpotlight = bundleIcon(
    PersonLightbulb20Filled,
    PersonLightbulb20Regular
);
const Search = bundleIcon(PersonSearch20Filled, PersonSearch20Regular);
const PerformanceReviews = bundleIcon(
    PreviewLink20Filled,
    PreviewLink20Regular
);
const JobPostings = bundleIcon(NotePin20Filled, NotePin20Regular);
const Interviews = bundleIcon(People20Filled, People20Regular);
const HealthPlans = bundleIcon(HeartPulse20Filled, HeartPulse20Regular);
const TrainingPrograms = bundleIcon(BoxMultiple20Filled, BoxMultiple20Regular);
const CareerDevelopment = bundleIcon(PeopleStar20Filled, PeopleStar20Regular);
const Analytics = bundleIcon(DataArea20Filled, DataArea20Regular);
const Reports = bundleIcon(
    DocumentBulletListMultiple20Filled,
    DocumentBulletListMultiple20Regular
);


type DrawerType = Required<DrawerProps>["type"];


export const NavDrawerDefault = (props: Partial<NavDrawerProps>) => {
    const styles = useStyles();
    const sectionStyles = useSectionStyles();
   
    const [isOpen, setIsOpen] = useState(true);
    const [activeItem, setActiveItem] = useState<string>();
    const [activeCatItem, setActiveCatItem] = useState<string>();
    const { appCount, entities, apps, entityCount, modules, modulesCount } = useCustomerManifest();

    const renderHamburgerWithToolTip = () => {
        return (
            <Tooltip content="Navigation" relationship="label">
                <Hamburger onClick={() => setIsOpen(!isOpen)} />
            </Tooltip>
        );
    };

    return (
        <div className={mergeClasses(sectionStyles.section, styles.root)}>
            <NavDrawer
                selectedCategoryValue={activeCatItem??""}
                selectedValue={activeItem??""}
                onNavCategoryItemToggle={(e, i) => { console.log(e); console.log(i); setActiveCatItem(old => old === i.value ? undefined:i.value as string); }}
                onNavItemSelect={(e, i) => {
                    console.log(e); console.log(i);
                    setActiveCatItem(i.categoryValue as string);

                        setActiveItem(old=> old === i.value? undefined : i.value as string);

                }}
                open={isOpen}
                type="inline"
                
            >
                <NavDrawerHeader>{renderHamburgerWithToolTip()}</NavDrawerHeader>

                <NavDrawerBody>
                    <AppItem
                        icon={<PersonCircle32Regular />}
                        as="a"
                        
                    >
                        EAVFW
                    </AppItem>         

                    <NavCategory value="modules">
                        <NavCategoryItem icon={<ModuleIcon />}>
                        Modules ({modulesCount})
                        </NavCategoryItem>
                        <NavSubItemGroup>
                            {modules.map((module, index) => (<NavSubItem key={`module-${module.id}`} value={`module-${module.id}`}>
                                {module.name}
                            </NavSubItem>))}

                        </NavSubItemGroup>
                    </NavCategory>
                    <NavCategory value="apps">
                       
                        <NavCategoryItem icon={<AppIcon />}>
                            Apps ({appCount})
                        </NavCategoryItem>
                        <NavSubItemGroup>
                            {Object.entries(apps).map(([appKey, app], index) => (<NavSubItem key={`app-${appKey}`}  value={`app-${appKey}`}>
                                {app.title ?? appKey}
                            </NavSubItem>))}
                          
                        </NavSubItemGroup>
                    </NavCategory>
                    <NavCategory value="tables">
                        <NavCategoryItem icon={<TableIcon />}>
                            Tables ({entityCount})
                        </NavCategoryItem>
                        <NavSubItemGroup>
                            {Object.entries(entities).map(([entityKey, entity], index) => (<NavSubItem style={{ paddingInlineStart:"22px", flexDirection: "column" }} key={`entity-${entityKey}`} value={`entity-${entityKey}`}>
                                {entity.pluralName ?? entity.title ?? entityKey}
                                {activeItem === `entity-${entityKey}` && <Tree aria-label="Default">
                                    <TreeItem itemType="branch">
                                        <TreeItemLayout iconBefore={<AttributeIcon />} >Attributes</TreeItemLayout>
                                        <Tree>
                                            {Object.entries(entities[entityKey].attributes).map(([attributeKey, attribute]) =>
                                                <TreeItem key={attributeKey} itemType="leaf">
                                                    <TreeItemLayout iconBefore={attributeKey === "[merge()]" ? <MergeIcon /> : <AttributeIcon/> }>{attribute.displayName ?? attributeKey}</TreeItemLayout>
                                                </TreeItem>
                                            )}
                                        </Tree>
                                    </TreeItem>
                                    <TreeItem itemType="branch">
                                        <TreeItemLayout iconBefore={<FormIcon />}>Forms</TreeItemLayout>
                                        <Tree>
                                            {Object.entries(entities[entityKey].forms ?? {}).map(([formKey, form]) =>
                                                <TreeItem key={formKey} itemType="leaf">
                                                    <TreeItemLayout>{formKey}</TreeItemLayout>
                                                </TreeItem>
                                            )}
                                        </Tree>
                                    </TreeItem>
                                    <TreeItem itemType="branch">
                                        <TreeItemLayout iconBefore={<ViewIcon/>}>Views</TreeItemLayout>
                                        <Tree>
                                            {Object.entries(entities[entityKey].views ?? {}).map(([viewKey, view]) =>
                                                <TreeItem key={viewKey} itemType="leaf">
                                                    <TreeItemLayout>{viewKey}</TreeItemLayout>
                                                </TreeItem>
                                            )}
                                        </Tree>
                                    </TreeItem>
                                     
                                </Tree>}

                            </NavSubItem>))}

                        </NavSubItemGroup>
                    </NavCategory>
                   
                    <NavItem
                     
                        icon={<EmployeeSpotlight />}
                        value="3"
                    >
                       Workflows
                    </NavItem>
                    <NavItem
                        
                        icon={<CodeIcon />}
                        value="sourceview"
                    >
                        Source view
                    </NavItem>

                </NavDrawerBody>
            </NavDrawer>
            <div className={styles.content}>
                {!isOpen && renderHamburgerWithToolTip()}
                
                {activeItem === "sourceview" && <ManifestSourceView />}
                {activeCatItem === "tables" && <TablesView />}
               
            </div>
        </div>
    );
};

export const CustomerEnvironmentDesigner: React.FC = ({ }) => {


    return <CustomerManifestDefinitionProvider entityName="customerapp" attributeName="Manifest">
        <NavDrawerDefault />
    </CustomerManifestDefinitionProvider> 
}

RegistereControl("CustomerEnvironmentDesigner", CustomerEnvironmentDesigner);