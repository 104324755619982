import { makeStyles, mergeClasses } from '@griffel/react';
export const carouselClassNames = {
    root: 'fui-Carousel'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        overflow: 'hidden'
    }
});
/**
 * Apply styling to the Carousel slots based on the state
 */ export const useCarouselStyles_unstable = (state)=>{
    'use no memo';
    const styles = useStyles();
    state.root.className = mergeClasses(carouselClassNames.root, styles.root, state.root.className);
    return state;
};
