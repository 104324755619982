import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider } from "@eavfw/apps";
import { EditRegular, Info16Regular, PlugDisconnected24Regular } from "@fluentui/react-icons";
import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { PropsWithChildren, Suspense, useCallback, useEffect, useMemo } from 'react'
import type { SelectionHookParams } from "@fluentui/react-utilities"
import {
    TableColumnDefinition,
    Dropdown, Option, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, TableSelectionCell,
    createTableColumn, useTableFeatures, Combobox, TableCellActions, useTableRowIdContext, useTableSelection, TableRowId, DialogBody, DialogTitle, DialogContent, DialogActions, DialogTrigger, Label, Tooltip, mergeClasses, useId, makeStyles, tokens, TooltipProps, TableFeaturesState, TableSelectionState, TableRowData
} from '@fluentui/react-components';
import { useState } from "react";
import { ChoiceType, IRecord, queryEntitySWR, useJsonFetcher, useSWRFetch } from "@eavfw/manifest";
import { Avatar, Button, CompoundButton, Input, Textarea, useArrowNavigationGroup, Dialog, DialogSurface, Field } from "@fluentui/react-components";

import { useEAVForm } from "@eavfw/forms";
import React from "react";
import moment from "moment";
import { Switch } from "@fluentui/react-components";
import type { SwitchProps } from "@fluentui/react-components";


function groupByTeams<T extends IRecord>(items: Array<T>) {
    let teams = {} as { [key: string]: Array<T> };
    for (let item of items) {
        let key = item.team?.id + item.class?.id;
        if (!(key in teams))
            teams[key] = [];

        teams[key].push(item);


    }
    console.log("GroupByTeams", teams);
    return teams;// Object.values(teams);
}

const useStyles = makeStyles({
    root: {
        display: "flex",
        columnGap: tokens.spacingVerticalS,
    },
    visible: {
        color: tokens.colorNeutralForeground2BrandSelected,
    },
});

export const Icon: React.FC<Partial<TooltipProps> & { label: string }> = ({ label, children, ...props }) => {
    const styles = useStyles();
    const contentId = useId("content");
    const [visible, setVisible] = React.useState(false);

    return (
        <div aria-owns={visible ? contentId : undefined} className={styles.root}>
            <Label>{label}</Label>
            <Tooltip
                content={{
                    children: children as React.ReactNode,
                    id: contentId,
                }}
                positioning="above-start"
                withArrow
                relationship="label"
                onVisibleChange={(e, data) => setVisible(data.visible)}
                {...props}
            >
                <Info16Regular
                    tabIndex={0}
                    className={mergeClasses(visible && styles.visible)}
                />
            </Tooltip>
        </div>
    );
};


const teamColumns: TableColumnDefinition<IRecord>[] = [
    createTableColumn<IRecord>({
        columnId: 'name',
    }),
    createTableColumn<IRecord>({
        columnId: 'phone',
    }),
    createTableColumn<IRecord>({
        columnId: 'payments',
    }),
    createTableColumn<IRecord>({
        columnId: 'status',
    }),
    createTableColumn<IRecord>({
        columnId: 'count',
    }),
    createTableColumn<IRecord>({
        columnId: 'entrytime',
    }),
    createTableColumn<IRecord>({
        columnId: 'entryreason',
    }),
    createTableColumn<IRecord>({
        columnId: 'teamname',
    }),
    createTableColumn<IRecord>({
        columnId: 'purpose',
    }),
    createTableColumn<IRecord>({
        columnId: 'userstatus',
    }),
    createTableColumn<IRecord>({
        columnId: 'classname',
    }),
    createTableColumn<IRecord>({
        columnId: 'date',
    }),
];

const notMatchedColumns = [
    createTableColumn<IRecord>({
        columnId: 'line',
    }),
    createTableColumn<IRecord>({
        columnId: 'description',
    })
];


type Actions = {
    getRows: TableFeaturesState<IRecord>["getRows"],
    rows: Array<TableRowData<IRecord> & { item: IRecord, onClick: any, onKeyDown: any, selected: boolean, appearance: any }>
} & TableSelectionState

type ActionsMap<T extends string> = {
    rows: `${T}Rows`,
    getRows: `get${T}Rows`,
    isRowSelected: `is${T}RowSelected`,
    toggleRow: `toggle${T}Row`,
    allRowsSelected: `all${T}RowsSelected`,
    clearRows: `clear${T}Rows`,
    deselectRow: `deselect${T}Row`,
    selectedRows: `selected${T}Rows`,
    selectionMode: `${T}selectionMode`,
    selectRow: `select${T}Row`,
    someRowsSelected: `some${T}RowsSelected`,
    toggleAllRows: `toggleAll${T}Rows`
}
type KeyedTableFeatures<T extends string> = { [K in keyof ActionsMap<T> as ActionsMap<T>[K]]: Actions[K]; }

const useKeyedTableFeatures = <Key extends string, T extends IRecord>(key: Key, columns: TableColumnDefinition<T>[], items: T[], options: Partial<SelectionHookParams> = {}) => {

    const {
        getRows,
        selection: { isRowSelected, toggleRow, allRowsSelected, clearRows, deselectRow, selectedRows, selectionMode, selectRow, someRowsSelected, toggleAllRows }
    } = useTableFeatures({
        columns: columns,
        items: items,
        getRowId: (item) => item.id
    }, [
        useTableSelection({
            selectionMode: 'multiselect',
            onSelectionChange: options.onSelectionChange,
            defaultSelectedItems: new Set([]),
        })
    ]);


    const enchancedRows = getRows((row) => {
        const selected = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === ' ' || e.key === 'Enter') {
                    toggleRow(e, row.rowId);
                }
            },
            selected,
            appearance: selected ? ('brand' as const) : ('none' as const)
        };
    });

    return {
        [`${key}Rows`]: enchancedRows,
        [`get${key}Rows`]: getRows,
        [`is${key}RowSelected`]: isRowSelected,
        [`toggle${key}Row`]: toggleRow,
        [`all${key}RowsSelected`]: allRowsSelected,
        [`clear${key}Rows`]: clearRows,
        [`deselect${key}Row`]: deselectRow,
        [`${key}SelectionMode`]: selectionMode,
        [`selected${key}Rows`]: selectedRows,
        [`select${key}Row`]: selectRow,
        [`some${key}RowsSelected`]: someRowsSelected,
        [`toggleAll${key}Rows`]: toggleAllRows
    } as KeyedTableFeatures<Key>
};


const TeamsTable: React.FC<PropsWithChildren<{
    setSelectedTeams: any,
    tournament: IRecord,
    showAll: boolean,
    showAllClass: boolean,
    paymentPerParticipant: { [key: string]: IRecord[] }, teams: { [key: string]: IRecord[] }
}>> = ({
    teams, paymentPerParticipant, tournament, setSelectedTeams, showAll, showAllClass
}) => {


        const keyboardNavAttr = useArrowNavigationGroup({
            axis: 'grid'
        });

    const items = useMemo(() => Object.entries(teams)
            
            .map(([teamid, x]) => {

                let amountPayedForTeam = x.map(x => x.user.id as string)
                    .reduce((sum, userid) => sum + (paymentPerParticipant[userid]?.map((xx) => xx.chargedamount as number)
                        .reduce((partialSum, a) => partialSum + a, 0) ?? 0), 0);

                return ({
                    id: teamid, // x[0].teamid,
                    teamname: x[0].team?.name, // x.map(b => b.user.name).join('/'),//  x[0].team?.name,
                    teamDetails: x.map(b => `${b.name} (${b.user.name})`).reduce((x, l) => x + l, ''),
                    classname: `${x[0].class?.name} (${x[0].class?.type?.name})` ,
                    date: moment(x[0].class?.starttime ?? tournament.starttime).format("MMM Do"),
                    payments: `${x.map(x => x.user.id).reduce((count, userid) => count + (paymentPerParticipant[userid]?.length ?? 0), 0)}, sum=${amountPayedForTeam}`,
                    paymentDetails: `${x.map(x => x.user.id).reduce((summary, userid) => summary + (paymentPerParticipant[userid]?.map((xx) => xx.name as string).reduce((partialSummary, line) => partialSummary + line + "\n", "") ?? ""), '')}`,
                    smscount: x.reduce((c, t) => c + t.user.customerreferences.reduce((cc: number, cr: IRecord) => cc + cr.smsmessages.length, 0), 0),
                    amountPayedForTeam,
                    classTypeName: x[0].class?.type?.name
                } as IRecord);
            }).filter(x => (showAll || x.amountPayedForTeam !== 600) && (showAllClass || x.classTypeName)), [teams, showAll, showAllClass]);

    console.log("Items", [showAll, items]);

        const { TeamsRows, allTeamsRowsSelected, someTeamsRowsSelected, toggleAllTeamsRows } = useKeyedTableFeatures("Teams", teamColumns,
            items
            , {
                onSelectionChange: (e, data) => {
                    console.log("Selection Change", [Array.from(data.selectedItems), teams]);
                    setSelectedTeams(Array.from(data.selectedItems).map(teamid => teams[teamid]).flat())
                }
            });

        return (<>
            <Label>Teams</Label>

            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell style={{ width: "20px" }} >#</TableHeaderCell>
                        <TableSelectionCell
                            checked={allTeamsRowsSelected ? true : someTeamsRowsSelected ? 'mixed' : false}
                            onClick={toggleAllTeamsRows}
                        />
                        <TableHeaderCell>Team</TableHeaderCell>
                        <TableHeaderCell>Class</TableHeaderCell>
                        <TableHeaderCell>Payments</TableHeaderCell>
                        <TableHeaderCell>SMS Count</TableHeaderCell>
                        <TableHeaderCell>Date</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody {...keyboardNavAttr}>
                    {TeamsRows.map(({ item, selected, onClick, onKeyDown, appearance, rowId }, n) => (
                        <TableRow
                            key={item.id}
                            onClick={onClick}
                            onKeyDown={onKeyDown}
                            aria-selected={selected}
                            appearance={appearance}
                        >
                            <TableCell style={{ width: "20px" }} width="20px">{n}</TableCell>
                            <TableSelectionCell tabIndex={0} checkboxIndicator={{ tabIndex: -1 }} checked={selected} />
                            <TableCell><Icon label={item.teamname}><div style={{ whiteSpace: "pre-line" }}>{item.teamDetails}</div></Icon></TableCell>
                            <TableCell>{item.classname}</TableCell>
                            <TableCell>
                                <Icon label={item.payments}><div style={{ whiteSpace: "pre-line" }}>{item.paymentDetails}</div></Icon>

                            </TableCell>
                            <TableCell>{item.smscount}</TableCell>
                            <TableCell>{item.date}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table></>
        )

    }
const SelectedTeamsParticipants = ({ selectedTeams }: { selectedTeams: IRecord[] }) => {


    const {
        TeamMemberRows,
        getTeamMemberRows,
        allTeamMemberRowsSelected,
        clearTeamMemberRows,
        deselectTeamMemberRow,
        isTeamMemberRowSelected,
        selectedTeamMemberRows,
        selectTeamMemberRow,
        someTeamMemberRowsSelected,
        TeamMemberselectionMode,
        toggleAllTeamMemberRows,
        toggleTeamMemberRow
    } = useKeyedTableFeatures("TeamMember", notMatchedColumns, selectedTeams);

    return (<>
        <Label>Participants</Label>
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell style={{ width: "20px" }} >#</TableHeaderCell>
                    <TableSelectionCell
                        checked={allTeamMemberRowsSelected ? true : someTeamMemberRowsSelected ? 'mixed' : false}
                        onClick={toggleAllTeamMemberRows}
                    />
                    <TableHeaderCell>Navn</TableHeaderCell>
                    <TableHeaderCell>Phone</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {TeamMemberRows.map(({ item, selected, onClick, onKeyDown, appearance, rowId }, n) => (
                    <TableRow
                        key={item.id}
                        onClick={onClick}
                        onKeyDown={onKeyDown}
                        aria-selected={selected}
                        appearance={appearance}
                    >
                        <TableCell style={{ width: "20px" }} width="20px">{n}</TableCell>
                        <TableSelectionCell tabIndex={0} checkboxIndicator={{ tabIndex: -1 }} checked={selected} />
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.user?.phone}</TableCell>
                        <TableCell>{item?.user?.email}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table></>
    )
}


RegistereRibbonButton("CUSTOM_MATCH_PAYMENTS", ({ key, ...props }) => {

    const { registerButton, events } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const { mutate: mutateForm } = useFormChangeHandlerProvider();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const app = useModelDrivenApp();

    registerButton({
        key: key,
        text: "Match Payments",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Match Payments",
        disabled: false,
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);

    const [selectedSMSTemplate, setSelectedSMSTemplate] = useState<string>();
    const { data: smstemplate } = queryEntitySWR(app.getEntityFromKey("SMS Template"),
        { '$select': 'name,id' });

    const [selectedEntryFee, setSelectedEntryFee] = useState<string[]>();
    const { data: entryfees } = queryEntitySWR(app.getEntityFromKey("Tournament Entry Fee"),
        { '$select': 'name,id,amount', '$filter': `tournamentid eq ${currentRecordId}` });

    //   const [selectedpaymentpointid, setselectedpaymentpints] = useState<string>();
    const { data: paymentpints } = queryEntitySWR(app.getEntityFromKey("Payment Point Route"),
        { '$select': 'name,id,paymentpointid,validfrom,validto', '$expand': "paymentpoint", '$filter': `tournamentid eq ${currentRecordId}` });


    //  const selectedPaymentPoint = useMemo(() => { return paymentpints?.items?.find(p => p.id === selectedpaymentpointid) }, [selectedpaymentpointid, paymentpints]);

    const { data: participants, isError, isLoading, mutate } = queryEntitySWR(app.getEntityFromKey("Tournament Participant"),
        {
            '$filter': `tournamentid eq ${currentRecordId} and status ne 0`,
            '$select': 'id,name,user,createdon,entrytime,entryreason,team,purpose,status,class',
            '$expand': `purpose($select=purpose),user($select=id,name,status,phone,email,customerreferences;$expand=customerreferences($select=smsmessages;$expand=smsmessages($select=id;$filter=tournamentid eq ${currentRecordId} and status eq 30))),team($select=name,id),class($select=name,id,starttime,type;$expand=type($select=name))`,
            '$orderby': 'entrytime'
        }, !hideDialog);

    const payments = queryEntitySWR(app.getEntityFromKey("Transaction"), {
        //  '$filter': `PaymentPoint/name eq 'MyShop (601215)'`,
        '$filter': `paymentpointid eq ${paymentpints?.items[0]?.paymentpointid} and chargeddate ge ${paymentpints?.items[0]?.validfrom} and chargeddate le ${paymentpints?.items[0]?.validto} `,
        '$expand': 'customer'
    }, (paymentpints?.items?.length > 0 ?? false) && !hideDialog)

    console.log("Payments", payments);
    console.log("Payments", participants);
    const paymentPerParticipant = useMemo<{ [key: string]: IRecord[] }>(() => {
        let obj = {} as any;
        for (let payment of payments?.data?.items ?? []) {

            let userid = payment.customer?.userid ?? 'unknown';
            if (!participants?.items?.find(participant => participant.user.id === userid))
                userid = 'unknown';

            if (!obj[userid]) {
                obj[userid] = [];
            }
            obj[userid].push(payment);
        }
        return obj;
    }, [payments?.data?.items, participants?.items]);
    const [tournament] = useEAVForm((state) => (state.formValues));
    console.log("Payments", paymentPerParticipant);

    const [showAll, setShowAll] = useState<boolean>(true);
    const [showAllClass, setShowAllClass] = useState<boolean>(true);

    const userStatusType = app.getEntityFromKey("User").attributes["Status"].type as ChoiceType;
    const userStatusMap = Object.fromEntries(Object.entries(userStatusType.options ?? {}).map(([x, y]) => [y, x]));
    const entryReasonType = app.getEntityFromKey("Tournament Participant").attributes["Entry Reason"].type as ChoiceType;
    const entryReasonMap = Object.fromEntries(Object.entries(entryReasonType.options ?? {}).map(([x, y]) => [y, x]));
    const statusType = app.getEntityFromKey("Tournament Participant").attributes["Status"].type as ChoiceType;
    const statusMap = Object.fromEntries(Object.entries(statusType.options ?? {}).map(([x, y]) => [y, x]));
    const teams = useMemo(() => groupByTeams(participants?.items ?? []), [participants?.items]);





    const keyboardNavAttr = useArrowNavigationGroup({
        axis: 'grid'
    });

    const {
        NotMatchedRows,
        getNotMatchedRows,
        allNotMatchedRowsSelected,
        clearNotMatchedRows,
        deselectNotMatchedRow,
        isNotMatchedRowSelected,
        NotMatchedselectionMode, someNotMatchedRowsSelected, toggleAllNotMatchedRows

    } = useKeyedTableFeatures("NotMatched", notMatchedColumns, paymentPerParticipant['unknown'] ?? [], {

    });

    const [selectedTeams, setSelectedTeams] = useState<IRecord[]>([]);






    return <Dialog open={!hideDialog}    >
        <DialogSurface style={{ minWidth: "90vw" }}>
            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle>
                    Betalinger
                </DialogTitle>
                <DialogContent>
                    <Switch label="Show All Payments" checked={showAll} onChange={(e, d) => { console.log("showAll", [e, d]); setShowAll(d.checked); }} />
                    <Switch label="Show All Classes" checked={showAllClass} onChange={(e, d) => { console.log("showAll", [e, d]); setShowAllClass(d.checked); }} />
                    <br />
                    <TeamsTable showAllClass={showAllClass} showAll={showAll} setSelectedTeams={setSelectedTeams} teams={teams} paymentPerParticipant={paymentPerParticipant} tournament={tournament} />
                    <br />
                    <SelectedTeamsParticipants selectedTeams={selectedTeams} />
                    <br />
                    <Label>Betalinger</Label>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell style={{ width: "20px" }} >#</TableHeaderCell>
                                <TableSelectionCell
                                    checked={allNotMatchedRowsSelected ? true : someNotMatchedRowsSelected ? 'mixed' : false}
                                    onClick={toggleAllNotMatchedRows}
                                />
                                <TableHeaderCell>Betalings Linje</TableHeaderCell>
                                <TableHeaderCell>Beskrivelse</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {NotMatchedRows.map(({ item, selected, onClick, onKeyDown, appearance, rowId }, n) => (
                                <TableRow
                                    key={item.id}
                                    onClick={onClick}
                                    onKeyDown={onKeyDown}
                                    aria-selected={selected}
                                    appearance={appearance}
                                >
                                    <TableCell style={{ width: "20px" }} width="20px">{n}</TableCell>
                                    <TableSelectionCell tabIndex={0} checkboxIndicator={{ tabIndex: -1 }} checked={selected} />
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button onClick={toggleHideDialog} appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <Button onClick={() => { }} appearance="primary">Send</Button>

                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>


});



RegistereRibbonButton("CUSTOM_SEND_PAYMENTLINKS", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const { mutate: mutateForm } = useFormChangeHandlerProvider();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const app = useModelDrivenApp();

    console.log("rows");

    registerButton({
        key: key,
        text: "Send Payment SMS",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Send Payment SMS",
        disabled: false,
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);



    const [selectedSMSTemplate, setSelectedSMSTemplate] = useState<string>();
    const { data: smstemplate } = queryEntitySWR(app.getEntityFromKey("SMS Template"),
        { '$select': 'name,id' });

    const [selectedEntryFee, setSelectedEntryFee] = useState<string[]>([]);
    const { data: entryfees } = queryEntitySWR(app.getEntityFromKey("Tournament Entry Fee"),
        { '$select': 'name,id,amount', '$filter': `tournamentid eq ${currentRecordId}` });

    const [selectedpaymentpointid, setselectedpaymentpints] = useState<string>();
    const { data: paymentpints } = queryEntitySWR(app.getEntityFromKey("Payment Point Route"),
        { '$select': 'name,id,paymentpointid,validfrom,validto', '$expand': "paymentpoint", '$filter': `tournamentid eq ${currentRecordId}` });


    const selectedPaymentPoint = useMemo(() => { return paymentpints?.items?.find(p => p.id === selectedpaymentpointid) }, [selectedpaymentpointid, paymentpints]);

    const { data, isError, isLoading, mutate } = queryEntitySWR(app.getEntityFromKey("Tournament Participant"),
        {
            '$filter': `tournamentid eq ${currentRecordId} and status ne 0`,
            '$select': 'id,name,user,createdon,entrytime,entryreason,team,purpose,status,class',
            '$expand': `purpose($select=purpose),user($select=id,name,status,phone,customerreferences;$expand=customerreferences($select=smsmessages;$expand=smsmessages($select=id;$filter=tournamentid eq ${currentRecordId} and status eq 30))),team($select=name),class($select=name,starttime,type;$expand=type($select=name))`,
            '$orderby': 'entrytime'
        });

    const payments = queryEntitySWR(app.getEntityFromKey("Transaction"), {
        //  '$filter': `PaymentPoint/name eq 'MyShop (601215)'`,
        '$filter': `paymentpointid eq ${selectedPaymentPoint?.paymentpointid} and chargeddate ge ${selectedPaymentPoint?.validfrom} and chargeddate le ${selectedPaymentPoint?.validto} `,
        '$expand': 'customer'
    }, typeof (selectedPaymentPoint?.paymentpointid) !== "undefined")

    console.log("Payments", payments);
    console.log("Payments", data);
    const paymentPerCustomer = useMemo(() => {
        let obj = {} as any;
        for (let payment of payments?.data?.items ?? []) {
            if (!obj[payment.customer?.userid]) {
                obj[payment.customer?.userid] = [];
            }
            obj[payment.customer?.userid].push(payment);
        }
        return obj;
    }, [payments?.data?.items]);
    const paymentPerTeam = useMemo(() => {
        let obj = {} as any;
        for (let payment of payments?.data?.items ?? []) {
            const teamid = data?.items?.find(x => x.user.id === payment.customer?.userid)?.team.name;

            if (!obj[teamid]) {
                obj[teamid] = [];
            }
            obj[teamid].push(payment);
        }
        return obj;
    }, [payments?.data?.items, data?.items]);

    const [tournament] = useEAVForm((state) => (state.formValues));
    console.log("paymentPerCustomer", paymentPerCustomer);
    console.log("paymentPerTeam", [payments?.data?.items, data?.items, paymentPerTeam]);

    const columns: TableColumnDefinition<IRecord>[] = React.useMemo(
        () => [
            createTableColumn<IRecord>({
                columnId: 'name',
            }),
            createTableColumn<IRecord>({
                columnId: 'phone',
            }),
            createTableColumn<IRecord>({
                columnId: 'payments',
            }),
            createTableColumn<IRecord>({
                columnId: 'status',
            }),
            createTableColumn<IRecord>({
                columnId: 'count',
            }),
            createTableColumn<IRecord>({
                columnId: 'entrytime',
            }),
            createTableColumn<IRecord>({
                columnId: 'entryreason',
            }),
            createTableColumn<IRecord>({
                columnId: 'teamname',
            }),
            createTableColumn<IRecord>({
                columnId: 'purpose',
            }),
            createTableColumn<IRecord>({
                columnId: 'userstatus',
            }),
            createTableColumn<IRecord>({
                columnId: 'classname',
            }),
            createTableColumn<IRecord>({
                columnId: 'date',
            }),
        ],
        [],
    );


    const userStatusType = app.getEntityFromKey("User").attributes["Status"].type as ChoiceType;
    const userStatusMap = Object.fromEntries(Object.entries(userStatusType.options ?? {}).map(([x, y]) => [y, x]));
    const entryReasonType = app.getEntityFromKey("Tournament Participant").attributes["Entry Reason"].type as ChoiceType;
    const entryReasonMap = Object.fromEntries(Object.entries(entryReasonType.options ?? {}).map(([x, y]) => [y, x]));
    const statusType = app.getEntityFromKey("Tournament Participant").attributes["Status"].type as ChoiceType;
    const statusMap = Object.fromEntries(Object.entries(statusType.options ?? {}).map(([x, y]) => [y, x]));

    const {
        getRows,
        selection: { allRowsSelected, someRowsSelected, toggleAllRows, toggleRow, isRowSelected, selectedRows },
    } = useTableFeatures(
        {
            columns,
            items: (data?.items ?? []).map(x => ({
                ...x,
                status: statusMap[x.status],
                payments: `${paymentPerCustomer[x.user.id]?.map((xx: any) => xx.chargedamount).reduce((partialSum: number, a: number) => partialSum + (a ?? 0), 0) ?? 0} (${paymentPerTeam[x.team?.name]?.map((xx: any) => xx.chargedamount).reduce((partialSum: number, a: number) => partialSum + (a ?? 0), 0) ?? 0})`,
                entryreason: entryReasonMap[x.entryreason],
                userstatus: userStatusMap[x.user.status],
                name: x.user.name,
                phone: x.user.phone,
                purpose: x.purpose?.purpose,
                teamname: x.team?.name,
                classname: x.class?.type?.name,
                date: moment(x.class?.starttime ?? tournament.starttime).format("MMM Do"),
                count: data?.items.filter(xx => xx.user.id === x.user.id).length,
                smscount: x.user.customerreferences.reduce((cc: number, cr: IRecord) => cc + cr.smsmessages.length, 0),
            } as IRecord)),
        },
        [
            useTableSelection({
                selectionMode: 'multiselect',
                defaultSelectedItems: new Set([]),
            }),
        ],
    );

    const rows = getRows((row) => {
        const selected = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === ' ' || e.key === 'Enter') {
                    toggleRow(e, row.rowId);
                }
            },
            selected,
            appearance: selected ? ('brand' as const) : ('none' as const)
        };
    });
    console.log("Rows", [rows]);

    const keyboardNavAttr = useArrowNavigationGroup({
        axis: 'grid'
    });

    const [variables, setVariables] = useState<string>(JSON.stringify({ myshop: "", centername: "", tournamentPrice: 300, tournamentdate: "" }, null, 4));

    useEffect(() => {
        var json = JSON.parse(variables);

        json.tournamentname = tournament.name;
        setVariables(JSON.stringify(json, null, 4));
    }, [tournament.name]);

    useEffect(() => {
        var json = JSON.parse(variables);
        var pointname = paymentpints?.items.filter(x => x.paymentpointid === selectedPaymentPoint?.paymentpointid)[0]?.paymentpoint.name;
        json.myshop = /.*\((.*)\)/.exec(pointname)?.[1];
        setVariables(JSON.stringify(json, null, 4));
    }, [selectedPaymentPoint?.paymentpointid, paymentpints?.items]);

    const startWorkflow = useCallback(() => {

        let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/${app.getEntity(currentEntityName).collectionSchemaName}/records/${currentRecordId}/workflows/e1d09533-32b3-bbaa-b2fe-0516a59c8bf1/runs`, {
            method: "POST",
            body: JSON.stringify({
                "smstemplateid": selectedSMSTemplate,
                "setNewUsersToActive": true,
                "variables": variables,
                "entryFees": selectedEntryFee,
                "participants": rows.filter(c => c.selected).map(c => ({ id: c.item.id, name: c.item.name }))
            }),
            credentials: "include"
        });
    }, [currentRecordId, rows, selectedSMSTemplate, variables])

    const [editRowId, setEditRowId] = useState<TableRowId>();

    return <Dialog open={!hideDialog}    >
        <DialogSurface style={{ minWidth: "90vw" }}>
            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle>
                    Udsendelse
                    <br />
                    <Field label="SMS Template">
                        <Combobox onOptionSelect={(e, data) => { console.log(data); setSelectedSMSTemplate(data.selectedOptions[0]); }}
                            aria-labelledby="combo-default260"
                            freeform
                            placeholder="Select an sms template"
                        >{(smstemplate?.items ?? []).map(x => <Option value={x.id} text={x.name!}>{x.name}</Option>)}</Combobox>

                    </Field>

                    <Field label="Entry Fee">
                        <Combobox multiselect onOptionSelect={(e, data) => { console.log(data); setSelectedEntryFee(data.selectedOptions); }}
                            aria-labelledby="combo-default260"
                            freeform
                            placeholder="Select an entry fee"
                        >{(entryfees?.items ?? []).map(x => <Option value={x.id} text={x.name!}>{x.name}</Option>)}</Combobox>

                    </Field>

                    <Field label="Payment Point">

                        <Combobox onOptionSelect={(e, data) => { setselectedpaymentpints(data.selectedOptions[0]) }}
                            aria-labelledby="combo-default260"
                            freeform
                            placeholder="Vælg paymentpoint"
                        >{(paymentpints?.items ?? []).map(x => <Option value={x.id} text={x.paymentpoint.name!}>{x.paymentpoint.name}</Option>)}</Combobox>
                    </Field>

                    <Field label="Variables"  ><Textarea rows={10} value={variables} onChange={(o, v) => setVariables(v.value ?? "{}")} /></Field>

                </DialogTitle>
                <DialogContent>

                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell style={{ width: "20px" }} >#</TableHeaderCell>
                                <TableSelectionCell
                                    checked={allRowsSelected ? true : someRowsSelected ? 'mixed' : false}
                                    onClick={toggleAllRows}
                                />
                                <TableHeaderCell>Name</TableHeaderCell>
                                <TableHeaderCell>Phone</TableHeaderCell>
                                <TableHeaderCell>Payments</TableHeaderCell>
                                <TableHeaderCell>Type</TableHeaderCell>
                                <TableHeaderCell>Date</TableHeaderCell>
                                <TableHeaderCell>Count</TableHeaderCell>
                                <TableHeaderCell>Sms Count</TableHeaderCell>
                                <TableHeaderCell>Status</TableHeaderCell>
                                <TableHeaderCell>Entry Time</TableHeaderCell>
                                <TableHeaderCell>Entry Reason</TableHeaderCell>
                                <TableHeaderCell>TeamName</TableHeaderCell>

                                <TableHeaderCell>Purpose</TableHeaderCell>
                                <TableHeaderCell>User Status</TableHeaderCell>

                            </TableRow>
                        </TableHeader>
                        <TableBody {...keyboardNavAttr}>
                            {rows.map(({ item, selected, onClick, onKeyDown, appearance, rowId }, n) => (
                                <TableRow
                                    key={item.id}
                                    onClick={onClick}
                                    onKeyDown={onKeyDown}
                                    aria-selected={selected}
                                    appearance={appearance}
                                >
                                    <TableCell>{n}</TableCell>
                                    <TableSelectionCell tabIndex={0} checkboxIndicator={{ tabIndex: -1 }} checked={selected} />
                                    {/*<TableCell>*/}
                                    {/*    {rowId == editRowId && <Input defaultValue={item.name} onChange={(e, data) => { console.log(data.value); item.name = data.value; }} onBlur={() => setEditRowId(undefined)} />}*/}
                                    {/*    {rowId != editRowId && item.name}*/}
                                    {/*    <TableCellActions>*/}
                                    {/*        <Button icon={<EditRegular />} appearance="subtle" onClick={() => setEditRowId(rowId)} />*/}

                                    {/*    </TableCellActions>*/}
                                    {/*</TableCell>*/}
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.phone}</TableCell>
                                    <TableCell>{item.payments}</TableCell>
                                    <TableCell>{item.classname}</TableCell>
                                    <TableCell>{item.date}</TableCell>
                                    <TableCell>{item.count}</TableCell>
                                    <TableCell>{item.smscount}</TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell>{item.entrytime}</TableCell>
                                    <TableCell>{item.entryreason}</TableCell>
                                    <TableCell>{item.teamname}</TableCell>
                                    <TableCell>{item.purpose}</TableCell>
                                    <TableCell>{item.userstatus}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button onClick={toggleHideDialog} appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <Button onClick={startWorkflow} appearance="primary">Send</Button>

                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>

});