import { makeStyles, mergeClasses } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const navSectionHeaderClassNames = {
    root: 'fui-NavSectionHeader'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        marginInlineStart: `10px`,
        marginBlock: '8px',
        ...typographyStyles.caption1Strong
    }
});
/**
 * Apply styling to the NavSectionHeader slots based on the state
 */ export const useNavSectionHeaderStyles_unstable = (state)=>{
    'use no memo';
    const styles = useStyles();
    state.root.className = mergeClasses(navSectionHeaderClassNames.root, styles.root, state.root.className);
    return state;
};
