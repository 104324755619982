
import { RegistereControl } from "@eavfw/apps"


export const CustomControlDemo: React.FC = ({ }) => {


    return <div>hello world</div>
}

RegistereControl("CustomControlDemo", CustomControlDemo);