
import { FieldProps } from '@rjsf/utils'
import { JSONSchema7 } from 'json-schema'
import { VFC, ChangeEvent, useState, useMemo, useEffect } from 'react'
import { RegistereControl, useModelDrivenApp } from '@eavfw/apps'
import { useEAVForm } from '@eavfw/forms'
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator'
import { UploadResult, UploadWorkerArguments, UploadWorkerEventType, UploadWorkerMessage } from './models'


export type FileUploadControlProps = {
    onChange: FieldProps['onChange']
    value: any
    entityName: string
    fieldName: string
    attributeName: string
    formName: string
    'x-control-props': { schemas: Array<{ uri: string, schema: JSONSchema7 }> }
}

export const FileUploadControl: VFC<FileUploadControlProps> =
    ({
        entityName,
        formName,
        fieldName,
        'x-control-props': { schemas } = {},
        attributeName,
        ...props
    }) => {
        const app = useModelDrivenApp();
        const entityAttributes = app.getAttributes(entityName);
        const attribute = entityAttributes[attributeName];

        const [data, { onChange: onFormDataChange }] = useEAVForm<any, any, any>((state) => state.formValues);
        const [percentComplete, setPercentComplete] = useState<number | undefined>(0);
        const [statusDescription, setStatusDescription] = useState('')

        const uploadWorker = useMemo(() => new Worker(new URL('./worker.ts', import.meta.url), { type: 'module', name: 'upload-worker' }), [])

        useEffect(() => {
            return () => uploadWorker.terminate();
        }, [uploadWorker])

        uploadWorker.onmessage = (e: MessageEvent<UploadWorkerMessage>) => {
            const message = e.data

            switch (message.type) {
                case UploadWorkerEventType.Progress:
                    setPercentComplete(message.data)
                    break

                case UploadWorkerEventType.Stage:
                    setStatusDescription(message.data)
                    break

                case UploadWorkerEventType.Error:
                    console.error(message.data);
                    setStatusDescription(message.data)
                    break

                case UploadWorkerEventType.Done:
                    const result: UploadResult = message.data
                    onFormDataChange(props => props[attribute.logicalName] = result.id)
                    break
            }
        }

        let onChange = async (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0]

            if (file) {
                uploadWorker.postMessage(new UploadWorkerArguments(file, data.id))
            }
        }

        return <div>
            <input
                onChange={onChange}
                type='file' />
            <ProgressIndicator label='Status' description={statusDescription} percentComplete={percentComplete} />
        </div>
    }


RegistereControl("FileUploadControl", FileUploadControl);