import { ContentBlock, EditorState } from "draft-js";
import { AtomicBlock } from "../Blocks/AtomicBlock";
import { AtomicEmbedlyComponent } from "../Blocks/EmbedlyButton";
import { AtomicSeparatorComponent } from "../Blocks/SeparatorButton";
import { Block } from "./constants";



const atomicRenderers = {
    embed: AtomicEmbedlyComponent,
    separator: AtomicSeparatorComponent,
};





export default (getEditorState: () => EditorState) => (contentBlock: ContentBlock) => {

    const type = contentBlock.getType();

    console.log("Render type", type);
    switch (type) {

        case Block.ATOMIC:
            return {
                component: AtomicBlock,
                editable: false,
                props: {
                    components: atomicRenderers,
                    getEditorState
                },
            };
        default: return null;


    }
}

