
import "./MobilePay";
import "./Aiia";
import "./Communication";
import "./RunRemoteWorkflowRibbonButton";
import "./OpenIdConnect/ResetPasswordRibbonButton";
import "./Tournaments/FetchMatches";
import "./ImportRecordsRibbonButton";
import "./Dinero/AuthorizeDineroRibbonButton";
//
import "./Merge/MergeDialogRibbonButton";