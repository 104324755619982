import { EAVApp, ModelDrivenApp, PageLayout, PageLayoutProps, ResolveFeature, useAppInfo, useModelDrivenApp, UserProvider } from "@eavfw/apps";
import { RouterBasedAppContextProvider } from "@eavfw/next";
import { createDOMRenderer, FluentProvider, GriffelRenderer, RendererProvider, SSRProvider, teamsLightTheme } from "@fluentui/react-components";
import { PortalCompatProvider } from "@fluentui/react-portal-compat";
import { NextComponentType, NextPageContext } from "next";
import { NextRouter } from "next/router";

import { FC } from "react";
import { useAppInsight } from "../components/AppInsights";
import manifest from "../manifest";

import "../themes/default";

import "../components";
import "../styles/global.css";
import '../styles/craftjs.css';
import '../styles/index.scss';

import { initializeIcons } from "@fluentui/font-icons-mdl2";

initializeIcons(/* optional base url */);

type AppProps = {
    pageProps: any;
    layoutProps: PageLayoutProps;
    Component: NextComponentType<NextPageContext, any, { app: ModelDrivenApp }>;
    err?: string;
    router: NextRouter,
    app: ModelDrivenApp,
    user: any
};


type EnhancedAppProps = AppProps & { renderer?: GriffelRenderer };


function getLayout(pageProps: any) {
    console.log("Getting layout: " + pageProps.layout);
    if (!pageProps.layout)
        return PageLayout;
    return ResolveFeature(pageProps.layout, false) ?? PageLayout;

}

function replaceLiteral(body: string, obj: any) {
    var iterLiteral = "\\[(.*?)\\]";
    let i = 0;
    var re = new RegExp(iterLiteral, "g");
    return body.replace(re, (s) => obj[s.substring(1, s.length - 1)]);
}

const MyAppLayout: React.FC<AppProps> = ({ Component: PageLayout, pageProps, err, router, layoutProps }) => {

    const rootKey = router.pathname + pageProps.layout;
    const pageKey = replaceLiteral(router.pathname, router.query);
    const ai = useAppInsight();

    ai.log("AppLayout Render: Path={Path} PathName={PathName}, RootKey={RootKey}, PageKey={PageKey} PageProps={@PageProps}",
        router.asPath, router.pathname, rootKey, pageKey, pageProps);

    const app = useModelDrivenApp();

    /**
     * App Info contains current App,Area, Entity, Record
     * */
    const appInfo = useAppInfo();

    /**
     * The EAVFW Layouts:
     *   - FormLayout : 
     *      Model Driven Grid Selected Provider, Ribbon Provider, Message Provider, 
     *      Progress Provider, TopBar, RibbonBar, MessageArea, Scrollable Pane, ProgressBar
     * */
    const Layout = getLayout(pageProps);

    /** 
     * <ThemeProvider theme={defaultTheme} {...props} id="web-container" />
     * @eavfw/apps/Layouts/RootLayout
     * */
    const RootLayout = ResolveFeature("RootLayout");


    /**
     * PageLayout is the component returned from /src/pages folder resolved from current route.
     * */

    return (

        <RootLayout {...pageProps} key={rootKey}>
            <Layout id="PageLayout" {...app._data} title={appInfo.title}>
                <PageLayout {...pageProps} app={app} key={pageKey} id="PageComponentId" />
            </Layout>
        </RootLayout>


    )
}


export const MyApp: React.FC<EnhancedAppProps> = ({ renderer, ...props }) => {

    const ai = useAppInsight();

    ai.log("App Render: AppProps={@AppProps}", props);

    return (
        <RendererProvider renderer={renderer || createDOMRenderer()}>
            <SSRProvider>
                <FluentProvider theme={teamsLightTheme}>
                    <PortalCompatProvider>
                        <EAVApp manifest={manifest}>
                            <RouterBasedAppContextProvider>
                                <UserProvider authorize={props.pageProps.authorize} >

                                    <MyAppLayout {...props} />

                                </UserProvider>
                            </RouterBasedAppContextProvider>
                        </EAVApp>
                    </PortalCompatProvider>
                </FluentProvider>
            </SSRProvider>
        </RendererProvider>
    );
}
export default MyApp;
 
