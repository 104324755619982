import { makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const carouselNavImageButtonClassNames = {
    root: 'fui-CarouselNavImageButton',
    image: 'fui-CarouselNavImageButton__image'
};
const imageButtonSize = 40;
const selectedImageButtonSize = 48;
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        boxSizing: 'content-box',
        padding: '0px',
        margin: `0 ${tokens.spacingHorizontalXS}`,
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        borderRadius: tokens.borderRadiusSmall,
        width: imageButtonSize + 'px',
        height: imageButtonSize + 'px',
        ':hover': {
            cursor: 'pointer'
        }
    },
    image: {
        width: imageButtonSize + 'px',
        height: imageButtonSize + 'px'
    },
    selected: {
        width: selectedImageButtonSize + 'px',
        height: selectedImageButtonSize + 'px'
    }
});
/**
 * Apply styling to the CarouselNavImageButton slots based on the state
 */ export const useCarouselNavImageButtonStyles_unstable = (state)=>{
    'use no memo';
    const { selected } = state;
    const styles = useStyles();
    state.root.className = mergeClasses(carouselNavImageButtonClassNames.root, styles.root, selected && styles.selected, state.root.className);
    if (state.image) {
        var _state_image;
        state.image.className = mergeClasses(carouselNavImageButtonClassNames.image, styles.image, selected && styles.selected, (_state_image = state.image) === null || _state_image === void 0 ? void 0 : _state_image.className);
    }
    return state;
};
