export class UploadWorkerArguments {
    public file: File
    public prefix: string

    constructor(file: File, prefix: string) {
        this.file = file
        this.prefix = prefix
    }
}

export enum UploadWorkerEventType {
    Error,
    Stage,
    Progress,
    Done
}

export class UploadWorkerMessage {
    public type: UploadWorkerEventType
    public data: any | undefined

    constructor(type: UploadWorkerEventType, data: any | undefined = undefined) {
        this.type = type
        this.data = data
    }
}

export interface UploadResult {
    id: string
    name: string
    path: string
    compressed: boolean
    size: number
    contentType: string
}