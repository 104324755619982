import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon } from "@eavfw/apps";

function trim(c: string, cc: string): string {
    if (c.endsWith(cc))
        return trim(c.slice(0, -1), cc);
    return c;
}
RegistereRibbonButton("CUSTOM_MOBILEPAY_APPROVE_SANDBOX_AGREEMENT", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();


    const app = useModelDrivenApp();

    registerButton({
        key: key,
        text: props.text ?? "Approve Agreement",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Approve Agreement",
        disabled: false,
        onClick: () => {

            //@ts-ignore
            let rsp = fetch(`${trim(process.env.NEXT_PUBLIC_BASE_URL,'/')}/providers/PaymentProviders.MobilePay/agreements/${currentRecordId}`, {
                method: "POST",
                body: JSON.stringify({                    
                }),
                credentials: "include"
            });
        }
        //workflow: props.workflow
        // onClick: onClick
    });

});