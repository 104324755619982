import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider } from "@eavfw/apps";
import { PlugDisconnected24Regular } from "@fluentui/react-icons";
import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Suspense, useCallback } from 'react'


import { useState } from "react";
import { useJsonFetcher, useSWRFetch } from "@eavfw/manifest";
import { CompoundButton, Dropdown, Option } from "@fluentui/react-components";
import { env } from "process";
import { useEAVForm } from "@eavfw/forms";

RegistereRibbonButton("CUSTOM_RETRIEVE_BANK_ACCOUNT_INFO", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const { mutate: mutateForm } = useFormChangeHandlerProvider();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [environment, setEnvironment] = useState("sandbox");
    const app = useModelDrivenApp();


    const { data, error, isLoading, mutate } = useSWRFetch(`/providers/PaymentProviders.Aiia/bankaccounts/${currentRecordId}`);

    registerButton({
        key: key,
        text: "Retrieve Bank Account Info",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Retrieve Bank Account Info",
        disabled: false,
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);
    const [baseUrl, jsonFetcher] = useJsonFetcher();
    const connect = useCallback(() => {
        fetch(`${baseUrl}/providers/PaymentProviders.MobilePay/paymentpoints/${currentRecordId}/connect`, {
            credentials: "include",
            method: "POST"
        })
            .then(() => {
                setTimeout(() => {
                    mutate(); mutateForm();
                }, 2500);
            });
    }, []);

    return <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>
        <pre>{JSON.stringify(data ?? {}, null, 4)}</pre>
        

    </SimpleDialog>

});



RegistereRibbonButton("CUSTOM_RETRIEVE_PROVIDER_INFO", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const { mutate: mutateForm } = useFormChangeHandlerProvider();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [environment, setEnvironment] = useState("sandbox");
    const app = useModelDrivenApp();


    const { data, error, isLoading, mutate } = useSWRFetch(`/providers/PaymentProviders.Aiia/transactions/${currentRecordId}`, typeof currentRecordId !== "undefined");

    registerButton({
        key: key,
        text: "Retrieve Provider Info",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Retrieve Provider Info",
        disabled: typeof currentRecordId === "undefined",
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [,currentRecordId,toggleHideDialog]);
    
    
    return <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>
        <pre>{JSON.stringify(data ?? {}, null, 4)}</pre> 
    </SimpleDialog>

});



const AuthorizePayoutComponent: React.FC = () => {
    const { currentEntityName, currentRecordId } = useAppInfo();
    const [environment, setEnvironment] = useState("sandbox");
    const [baseUrl] = useJsonFetcher();

    const connect = useCallback(() => {
        window.open(`${baseUrl}/providers/PaymentProviders.Aiia/bankaccounts/${currentRecordId}/authorize-payouts?redirecturi=${encodeURIComponent(location.protocol + "//" + location.host + location.pathname + location.search)}`, '_blank');
    }, [currentRecordId])



    return <>
         
        <CompoundButton
            icon={<PlugDisconnected24Regular />}
            secondaryContent={`Authorize Payouts`} onClick={connect}
        >
            Payout
        </CompoundButton>
    </>
}


RegistereRibbonButton("CUSTOM_AUTHORIZE_PAYOUTS", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
     
    registerButton({
        key: key,
        text: "Authorize Payouts",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Authorize Payouts",
        disabled: false,
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);
    
    return <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>
        <AuthorizePayoutComponent /> 
    </SimpleDialog>

});