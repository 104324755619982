import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { useDocument } from "../Hooks/useDocument";
import { Locale } from "../Types/Locale";
import { ManifestDefinition, queryEntitySWR } from "@eavfw/manifest";
import { useAppInfo, useEAVApp } from "@eavfw/apps";
import { ODataBuilder } from "@eavfw/query";

type module = {
    id: string, name: string
}
type moduleref = { id:string, app: { name: string }, appmodule: module }
type CustomerManifestContextType = ReturnType<typeof useDocument> & {
    apps: ManifestDefinition["apps"],
    appCount:number,
    entities: ManifestDefinition["entities"]
    entityCount: number,
    modules: Array<module>
    modulesCount:number
};
export const CustomerManifestDefinitionContext = createContext<CustomerManifestContextType | undefined>(undefined);
export const useCustomerManifest = () => useContext(CustomerManifestDefinitionContext)!;

type CustomerEnvironmentDesignerProviderProps = {
    entityName: string;
    attributeName: string;
    designerLocale?: Locale;
}

export const CustomerManifestDefinitionProvider: React.FC<PropsWithChildren<CustomerEnvironmentDesignerProviderProps>> = ({ entityName, attributeName, children, designerLocale }) => {

    const quickform = useDocument(entityName, attributeName, designerLocale);
    const apps = useMemo(() => quickform.manifest.apps ?? {}, [quickform]);
    const entities = useMemo(() => quickform.manifest.entities ?? {}, [quickform]);
    const entityCount = useMemo(() => Object.keys(entities).length, [entities]);
    const appCount = useMemo(() => Object.keys(apps).length, [apps]);

    const { currentRecordId } = useAppInfo();
    const [app, appinfo] = useEAVApp();
    const { data: modulerefs, isError, isLoading } = queryEntitySWR<moduleref>(app.model.getEntityFromKey("App Module Reference"),
        new ODataBuilder<moduleref>()
            .expand("appmodule", x => x.select("id", "name"))
            .filter(`appid eq ${currentRecordId}`)
            .build());
    const modules = useMemo(() => modulerefs?.items?.map(x => x.appmodule) ?? [], [modulerefs]);
    return (
        <CustomerManifestDefinitionContext.Provider value={{ ...quickform, apps, entities, appCount, entityCount, modulesCount: modules.length, modules }}>
            {children}
        </CustomerManifestDefinitionContext.Provider>
    )
}