import { makeResetStyles, makeStyles, mergeClasses } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
import { createCustomFocusIndicatorStyle } from '@fluentui/react-tabster';
import { treeItemLevelToken } from '../../utils/tokens';
import { treeItemLayoutClassNames } from '../TreeItemLayout/useTreeItemLayoutStyles.styles';
import { treeItemPersonaLayoutClassNames } from '../TreeItemPersonaLayout/useTreeItemPersonaLayoutStyles.styles';
export const treeItemClassNames = {
    root: 'fui-TreeItem'
};
const useBaseStyles = makeResetStyles({
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundColor: tokens.colorSubtleBackground,
    color: tokens.colorNeutralForeground2,
    paddingRight: tokens.spacingHorizontalNone,
    // if using createCustomFocusIndicatorStyle then we need to remove default outline styles provided by the browser
    ':focus': {
        outlineStyle: 'none'
    },
    ':focus-visible': {
        outlineStyle: 'none'
    },
    // This adds the focus outline for the TreeItemLayout element
    ...createCustomFocusIndicatorStyle({
        borderRadius: tokens.borderRadiusMedium,
        outlineColor: tokens.colorStrokeFocus2,
        outlineRadius: tokens.borderRadiusMedium,
        // FIXME: tokens.strokeWidthThick causes some weird bugs
        outlineWidth: '2px',
        outlineStyle: 'solid'
    }, {
        customizeSelector: (selector)=>`${selector} > .${treeItemLayoutClassNames.root}, ${selector} > .${treeItemPersonaLayoutClassNames.root}`
    })
});
const useStyles = makeStyles({
    ...Object.fromEntries(Array.from({
        length: 10
    }, (_, index)=>[
            `level${index + 1}`,
            {
                [treeItemLevelToken]: index + 1
            }
        ]))
});
/**
 * Apply styling to the TreeItem slots based on the state
 */ export const useTreeItemStyles_unstable = (state)=>{
    'use no memo';
    const baseStyles = useBaseStyles();
    const styles = useStyles();
    const { level } = state;
    state.root.className = mergeClasses(treeItemClassNames.root, baseStyles, isStaticallyDefinedLevel(level) && styles[`level${level}`], state.root.className);
    return state;
};
function isStaticallyDefinedLevel(level) {
    return level >= 1 && level <= 10;
}
