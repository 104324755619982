import { tokens } from '@fluentui/react-theme';
import { makeStyles, mergeClasses } from '@griffel/react';
export const optionGroupClassNames = {
    root: 'fui-OptionGroup',
    label: 'fui-OptionGroup__label'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingHorizontalXXS,
        '&:not(:last-child)::after': {
            content: '""',
            borderBottom: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke2}`,
            display: 'block',
            paddingBottom: tokens.spacingHorizontalXS,
            margin: `0 ${`calc(${tokens.spacingHorizontalXS} * -1)`} ${tokens.spacingVerticalXS}`
        }
    },
    label: {
        borderRadius: tokens.borderRadiusMedium,
        color: tokens.colorNeutralForeground3,
        display: 'block',
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase200,
        padding: `${tokens.spacingHorizontalS} ${tokens.spacingHorizontalSNudge}`
    }
});
/**
 * Apply styling to the OptionGroup slots based on the state
 */ export const useOptionGroupStyles_unstable = (state)=>{
    'use no memo';
    const styles = useStyles();
    state.root.className = mergeClasses(optionGroupClassNames.root, styles.root, state.root.className);
    if (state.label) {
        state.label.className = mergeClasses(optionGroupClassNames.label, styles.label, state.label.className);
    }
    return state;
};
