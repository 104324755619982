


import { AtomicBlockUtils, EditorChangeType, EditorState } from 'draft-js';
import React, { useCallback } from 'react';


import styles from "../AddButton/AddButton.module.scss";
import { Block } from '../Utils/constants';
import { addNewBlock } from "../Utils/addNewBlock"
import { FontIcon, mergeStyles } from '@fluentui/react';


export type SeparatorButtonProps = {
    close: Function;
    updateEditor: (cb: (state: EditorState) => EditorState) => void;
}
export const SeparatorButton: React.FC<SeparatorButtonProps> = ({ close, updateEditor }) => {


    const onClick = useCallback(() => {

        updateEditor((editorState) => {
            console.log("Adding Attomic");
            const content = editorState.getCurrentContent();
            const contentWithEntity = content.createEntity('separator', 'IMMUTABLE', {});
            const entityKey = contentWithEntity.getLastCreatedEntityKey();


            close();


            return AtomicBlockUtils.insertAtomicBlock(
                EditorState.push(editorState, contentWithEntity, 'create-entity' as EditorChangeType),
                entityKey,
                '-'
            );

            
        });


    }, [updateEditor]);



    return <button title="Add a separator" className={styles.mdSBButton} onClick={onClick} type="button">
        <FontIcon aria-label="SkypeMinus" iconName="SkypeMinus" />
    </button>


}

export const AtomicSeparatorComponent: React.FC = (props) => (
    <hr />
);