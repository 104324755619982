import { RegistereControl, useModelDrivenApp } from "@eavfw/apps";
import { queryEntitySWR } from "@eavfw/manifest";
import { Button, GriffelStyle, makeStyles, ToggleButton, Input, Field } from "@fluentui/react-components";
import { useCallback, useEffect, useState } from "react";


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
        '> *': {
            textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
    },
    grouptitle: {
        width: "100px",
        marginRight:"10px",
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
        '> *': {
            textOverflow: 'ellipsis',                    
        },
        '> :not(:first-child)': {            
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
    },
})


const StatsRecorder: React.FC = () => {
    const styles = useStyles();

    const [teamA, setTeamA] = useState(false);
    const [teamB, setTeamB] = useState(false);

    const [teamAName, setTeamAName] = useState("Team A");
    const [teamBName, setTeamBName] = useState("Team B");

    const checkA = useCallback(() => {
        setTeamA(!teamA);
        setTeamB(false);
    }, [teamA]);
    const checkB = useCallback(() => {
        setTeamB(!teamB);
        setTeamA(false);
    }, [teamB]);

    const [smashAWin,setSmashAWin] = useState(0);
    const [smashALos, setSmashALos] = useState(0);
    const [smashBWin, setSmashBWin] = useState(0);
    const [smashBLos, setSmashBLos] = useState(0);

    const setSmashWin = useCallback(() => {
        if (teamA) {
            setSmashAWin(smashAWin + 1);
        }
        if (teamB) {
            setSmashBWin(smashBWin + 1);
        }


    }, [smashAWin, smashBWin, teamA,teamB]);
    const setSmashLos = useCallback(() => {
        if (teamA) {
            setSmashALos(smashALos + 1);
        }
        if (teamB) {
            setSmashBLos(smashBLos + 1);
        }


    }, [smashALos, smashBLos, teamA, teamB]);

    const [teamAfejl, setTeamAFejl] = useState(0);
    const [teamBfejl, setTeamBFejl] = useState(0);
    const [teamAGolden, setTeamAGolden] = useState(0);
    const [teamBGolden, setTeamBGolden] = useState(0);

   
    const app = useModelDrivenApp();
    const stats = queryEntitySWR(app.getEntityFromKey("Statistic Recording"));
    const [isReady, setIsReady] = useState(false);

    const reset = useCallback(() => {

        const record = stats.data?.items?.[0];

        let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/${app.getEntityFromKey("Statistic Recording").collectionSchemaName}/records/${record.id}`, {
            method: "PATCH",
            body: JSON.stringify({
                "qualitativeresult": JSON.stringify({
                    ae: 0, be: 0, ag: 0, bg: 0, asw: 0, asl: 0, bsw: 0, bsl: 0,
                    ap: "0",
                    bp: "0",
                    as1: "0",
                    as2: "0",
                    as3: "0",
                    bs1: "0",
                    bs2: "0",
                    bs3: "0",
                    an: "Team A",
                    bn: "Team B"
                })
            }),
            credentials: "include"
        });

        rsp.then(() => {
            stats.mutate();

        });
       
    }, [stats.data?.items?.[0], stats.mutate]);

    const [team_a_parti, setTeam_a_parti] = useState("0");
    const [team_b_parti, setTeam_b_parti] = useState("0");
    const [team_a_set1, setTeam_a_set1] = useState("0");
    const [team_a_set2, setTeam_a_set2] = useState("0");
    const [team_a_set3, setTeam_a_set3] = useState("0");
    const [team_b_set1, setTeam_b_set1] = useState("0");
    const [team_b_set2, setTeam_b_set2] = useState("0");
    const [team_b_set3, setTeam_b_set3] = useState("0");

    useEffect(() => {
        if (!isReady)
            return;

        const record = stats.data?.items?.[0];
        if (record) {
            let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/${app.getEntityFromKey("Statistic Recording").collectionSchemaName}/records/${record.id}`, {
                method: "PATCH",
                body: JSON.stringify({
                    "qualitativeresult": JSON.stringify({
                        ae: teamAfejl,
                        be: teamBfejl,
                        ag: teamAGolden,
                        bg: teamBGolden,
                        asw: smashAWin,
                        asl: smashALos,
                        bsw: smashAWin,
                        bsl: smashBLos,
                        ap: team_a_parti,
                        bp: team_b_parti,
                        as1: team_a_set1,
                        as2: team_a_set2,
                        as3: team_a_set3,
                        bs1: team_b_set1,
                        bs2: team_b_set2,
                        bs3: team_b_set3,
                        an: teamAName,
                        bn: teamBName
                    })
                }),
                credentials: "include"
            });
            rsp.then(r => {
                if (!r.ok) {
                    alert("reload");
                }
            })
        } else {
            let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/${app.getEntityFromKey("Statistic Recording").collectionSchemaName}/records`, {
                method: "POST",
                body: JSON.stringify({
                    "qualitativeresult": JSON.stringify({
                        ae: teamAfejl,
                        be: teamBfejl,
                        ag: teamAGolden,
                        bg: teamBGolden,
                        asw: smashAWin,
                        asl: smashALos,
                        bsw: smashAWin,
                        bsl: smashBLos,
                        ap: team_a_parti,
                        bp: team_b_parti,
                        as1: team_a_set1,
                        as2: team_a_set2,
                        as3: team_a_set3,
                        bs1: team_b_set1,
                        bs2: team_b_set2,
                        bs3: team_b_set3,
                        an: teamAName,
                        bn: teamBName
                    })
                }),
                credentials: "include"
            });
            rsp.then(r => {
                if (!r.ok) {
                    alert("reload");
                }
                stats.mutate();
            })
        }

    }, [teamAfejl, teamBfejl, teamAGolden, teamBGolden, smashALos, smashBLos, smashAWin, smashBWin, teamAName, teamBName,
         team_a_parti,
        team_b_parti,
         team_a_set1,
         team_a_set2,
         team_a_set3,
         team_b_set1,
         team_b_set2,
         team_b_set3,
        stats.data?.items?.[0]]);

    useEffect(() => {
        if (stats.data?.items) {
            const record = stats.data?.items?.[0];
            if (record) {
                let data = JSON.parse(record.qualitativeresult);
                setTeamAFejl(data.ae);
                setTeamBFejl(data.be);
                setTeamAGolden(data.ag);
                setTeamBGolden(data.bg);
                setSmashAWin(data.asw);
                setSmashALos(data.asl);
                setSmashBWin(data.bsw);
                setSmashBLos(data.bsl);
                setTeam_a_parti(data.ap);
                setTeam_b_parti(data.bp);
                setTeam_a_set1(data.as1);
                setTeam_a_set2(data.as2);
                setTeam_a_set3(data.as3);
                setTeam_b_set1(data.bs1);
                setTeam_b_set1(data.bs2);
                setTeam_b_set3(data.bs3);
                setTeamAName(data.an ?? "Team A");
                setTeamBName(data.bn ?? "Team B");

                 //ap: team_a_parti,
                 //       bp: team_b_parti,
                 //       as1: team_a_set1,
                 //       as2: team_a_set2,
                 //       as3: team_a_set3,
                 //       bs1: team_b_set1,
                 //       bs2: team_b_set2,
                 //       bs3: team_b_set3,
            }
            setIsReady(true);
        }
    }, [stats.isLoading, stats.data?.items]);


  

    if (!stats.data?.items) {
        return <div>loading</div>
    }



    
    return <div className={styles.root}>
        <div className={styles.row}>
            <Button shape="square" onClick={reset}>Reset</Button>         
        </div> 
        <div className={styles.row}>
            <div className={styles.grouptitle}>Team A:</div>
            <Input defaultValue={teamAName} onBlur={(a) => { setTeamAName(a.target.value); }} />
            <div className={styles.grouptitle}>Team B:</div>
            <Input defaultValue={teamBName} onBlur={(a) => { setTeamBName(a.target.value); }} />
            
        </div> 
        <div className={styles.row}>
            <div className={styles.grouptitle}>15/30/40</div>
          
            <Input defaultValue="0" value={team_a_parti } />
           
            <Button onClick={() => { setTeam_a_parti(team_a_parti == "0" ? "0" : team_a_parti == "15" ? "0" : team_a_parti == "30" ? "15" : "30") }} shape="square">-</Button>
            <Button onClick={() => { setTeam_a_parti(team_a_parti == "0" ? "15" : team_a_parti == "15" ? "30" : team_a_parti == "30" ? "40" : "0") }} shape="square">+</Button>

            <Input defaultValue="0" value={team_b_parti} />
           
            <Button onClick={() => { setTeam_b_parti(team_b_parti == "0" ? "0" : team_b_parti == "15" ? "0" : team_b_parti == "30" ? "15" : "30") }} shape="square">-</Button>
            <Button onClick={() => { setTeam_b_parti(team_b_parti == "0" ? "15" : team_b_parti == "15" ? "30" : team_b_parti == "30" ? "40" : "0") }} shape="square">+</Button>
            <Button onClick={() => { setTeam_a_parti("0"); setTeam_b_parti("0"); }} shape="square">reset</Button>
        </div> 

        <div className={styles.row}> 
            <div className={styles.grouptitle}>Set1:</div>

            <Input defaultValue="0" value={team_a_set1} />
            <Button onClick={() => { setTeam_a_set1((parseInt(team_a_set1) - 1).toString()) }} shape="square">-1</Button>
            <Button onClick={() => { setTeam_a_set1((parseInt(team_a_set1) + 1).toString()) }} shape="square">+1</Button>
            <Input defaultValue="0" value={team_b_set1} />
            <Button onClick={() => { setTeam_b_set1((parseInt(team_b_set1) - 1).toString()) }} shape="square">-1</Button>
            <Button onClick={() => { setTeam_b_set1((parseInt(team_b_set1) + 1).toString()) }} shape="square">+1</Button>
        </div> 
        <div className={styles.row}>
            <div className={styles.grouptitle}>Set2:</div>
            <Input defaultValue="0" value={team_a_set2} />
            <Button onClick={() => { setTeam_a_set2((parseInt(team_a_set2) - 1).toString()) }} shape="square">-1</Button>
            <Button onClick={() => { setTeam_a_set2((parseInt(team_a_set2) + 1).toString()) }} shape="square">+1</Button>
            <Input defaultValue="0" value={team_b_set2} />
            <Button onClick={() => { setTeam_b_set2((parseInt(team_b_set2) - 1).toString()) }} shape="square">-1</Button>
            <Button onClick={() => { setTeam_b_set2((parseInt(team_b_set2) + 1).toString()) }} shape="square">+1</Button>
        </div> 
        <div className={styles.row}>
            <div className={styles.grouptitle}>Set3:</div>
            <Input defaultValue="0" value={team_a_set3} />
            <Button onClick={() => { setTeam_a_set3((parseInt(team_a_set3) - 1).toString()) }} shape="square">-1</Button>
            <Button onClick={() => { setTeam_a_set3((parseInt(team_a_set3) + 1).toString()) }} shape="square">+1</Button>
            <Input defaultValue="0" value={team_b_set3} />
            <Button onClick={() => { setTeam_b_set3((parseInt(team_b_set3) - 1).toString()) }} shape="square">-1</Button>
            <Button onClick={() => { setTeam_b_set3((parseInt(team_b_set3) + 1).toString()) }} shape="square">+1</Button>
        </div> 

        <div className={styles.row}>
            <div className={styles.grouptitle}>Smash:</div>
            <ToggleButton checked={teamA} onClick={checkA} shape="square">Team A</ToggleButton>
            <ToggleButton checked={teamB} onClick={checkB} shape="square">Team B</ToggleButton>
            
            {(teamA || teamB) && <Button shape="square" onClick={setSmashWin}>Win ({(teamA ? smashAWin : teamB ? smashBWin : '')})</Button>}
            {(teamA || teamB) && <Button shape="square" onClick={setSmashLos}>Los ({(teamA ? smashALos : teamB ? smashBLos : '')})</Button>}
        </div>
        <div className={styles.row}>
            <div className={styles.grouptitle}>Fejl:</div>
            <Button onClick={() => setTeamAFejl(teamAfejl + 1)} shape="square">Team A ({teamAfejl})</Button>
            <Button onClick={() => setTeamBFejl(teamBfejl + 1)} shape="square">Team B ({teamBfejl})</Button>
        </div>
        <div className={styles.row}>
            <div className={styles.grouptitle}>Goalden Point:</div>
            <Button onClick={() => setTeamAGolden(teamAGolden + 1)} shape="square">Team A ({teamAGolden})</Button>
            <Button onClick={() => setTeamBGolden(teamBGolden + 1)} shape="square">Team B ({teamBGolden})</Button>
        </div>
    </div>
}

RegistereControl("StatsRecorder", StatsRecorder);