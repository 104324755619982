


import { AtomicBlockUtils, EditorChangeType, EditorState } from 'draft-js';
import React, { useCallback, useState } from 'react';


import styles from "../AddButton/AddButton.module.scss";
import { Block } from '../Utils/constants';
import { addNewBlock } from "../Utils/addNewBlock"
import { FontIcon, mergeStyles } from '@fluentui/react';
import { useEffect } from 'react';


export type EmbedlyButtonProps = {
    close: Function;
    updateEditor: (cb: (state: EditorState) => EditorState) => void;
}
export const EmbedlyButton: React.FC<EmbedlyButtonProps> = ({ close, updateEditor }) => {


    const addEmbedURL = useCallback((url: string) => {

        updateEditor((editorState) => {
            console.log("Adding Attomic");
            const content = editorState.getCurrentContent();
            const contentWithEntity = content.createEntity('embed', 'IMMUTABLE', { url });
            const entityKey = contentWithEntity.getLastCreatedEntityKey();


            close();


            return AtomicBlockUtils.insertAtomicBlock(
                EditorState.push(editorState, contentWithEntity, 'create-entity' as EditorChangeType),
                entityKey,
                'E'
            );


        });

    }, [updateEditor]);

    const onClick = useCallback(() => {

        const url = window.prompt('Enter a URL', 'https://www.youtube.com/watch?v=PMNFaAUs2mo');

        close();

        if (!url) {
            return;
        }

        addEmbedURL(url);
       


    }, []);



    return <button title="Add an Embed" className={styles.mdSBButton} onClick={onClick} type="button">
        <FontIcon aria-label="Embed" iconName="Embed" />
    </button>


}

function getScript() {
    const script = document.createElement('script');
    script.async = true;
    script.src = '//cdn.embedly.com/widgets/platform.js';
    script.onload = () => {
        //@ts-ignore
        window.embedly();
    };
    document.body.appendChild(script);
}

function renderEmbedly() {
        //@ts-ignore

    if (window.embedly) {
        //@ts-ignore

        window.embedly();
    } else {
        getScript();
    }
}


export const AtomicEmbedlyComponent: React.FC<{ data: { url: string } }> = ({ data: { url } }) => {
    const [showIframe, setshowIframe] = useState(false);

    useEffect(() => {
        renderEmbedly();
    }, []);

    const innerHTML = `<div><a class="embedly-card" href="${url}" data-card-controls="0" data-card-theme="dark">Embedded ― ${url}</a></div>`;
    return (
        <div className="md-block-atomic-embed">
            <div dangerouslySetInnerHTML={{ __html: innerHTML }} />
        </div>
    );
}