
import { RegistereControl } from "@eavfw/apps"

import editorStyles from './editorStyles.module.scss';
import '@draft-js-plugins/side-toolbar/lib/plugin.css';
import Editor, { } from '@draft-js-plugins/editor';
import { AtomicBlockUtils, ContentBlock, ContentState, convertFromRaw, convertToRaw, EditorChangeType, EditorState, RichUtils } from "draft-js";
import createSideToolbarPlugin from '@draft-js-plugins/side-toolbar';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AddButton, AddButtonProps } from "./AddButton/AddButton";
import { BreakButton } from "./Blocks/BreakButton";
import { ImageButton } from "./Blocks/ImageButton";
import { AtomicSeparatorComponent, SeparatorButton } from "./Blocks/SeparatorButton";
import defaultRenderer from "./Utils/defaultRenderer";
import RenderMap from "./Utils/RenderMap";
import blockStyleFn from "./Utils/BlockStyleFn";
import { useEffect } from "react";
import { Block } from "./Utils/constants";
import { EmbedlyButton } from "./Blocks/EmbedlyButton";
import { useEAVForm } from "@eavfw/forms";
import { gzip, ungzip } from "pako";

// Creates an Instance. At this step, a configuration object can be passed in
// as an argument.
const sideToolbarPlugin = createSideToolbarPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const { SideToolbar } = sideToolbarPlugin;
const plugins = [inlineToolbarPlugin];


export type BlogEditorControlProps = {}

const defaultSideButtons = [

    { title: "Image", component: ImageButton },
    { title: "Seperator", component: SeparatorButton },
    { title: "Embedly", component: EmbedlyButton }
];



// The Editor accepts an array of plugins. In this case, only the sideToolbarPlugin
// is passed in, although it is possible to pass in multiple plugins.
const BlogEditorControl: React.FC<BlogEditorControlProps> = () => {


    const [{ content, id }, { onChange: updateFormData }] = useEAVForm((state) => ({ content: state.formValues.content, id:state.formValues.id }));
    console.log("Loaded Data", [content,id]);

    const editor = useRef<Editor>(null);
    const [editorState, setState] = useState(content ? EditorState.createWithContent(convertFromRaw(JSON.parse(ungzip(new Uint8Array(atob(content.data).split("").map(c => c.charCodeAt(0))), { to: "string" }) as string))) : EditorState.createEmpty());
    const onChange = useCallback((a: EditorState) => {
        setState(a);
        console.log(a);
       
    }, []);
    const onFocus = useCallback(() => editor.current?.focus(), [])

    const onTab = useCallback((e: React.KeyboardEvent<{}>) => {
        console.log("Tab");
        const newEditorState = RichUtils.onTab(e, editorState, 2);

        if (newEditorState !== editorState) {
            console.log("new state", newEditorState);
            setState(newEditorState);
        }
    }, []);

    const [editorEnabled, seteditorEnabled] = useState(true);
    const editorClass = useMemo(() => `md-RichEditor-editor${!editorEnabled ? ' md-RichEditor-readonly' : ''} ${editorStyles.editor}`, [editorEnabled]);

    const [showAddButton, setShowAddButton] = useState(false);


    const updateEditor = useCallback<AddButtonProps["updateEditor"]>((cb) => {

        const newEditorState = cb(editorState);

        if (newEditorState && newEditorState !== editorState) {
            console.log("new state", newEditorState);
            setState(newEditorState);


        }
    }, [editorState]);

    const blockRenderer = useMemo(() => defaultRenderer(() => editorState), []);

    useEffect(() => {

        updateFormData((state) => {

            let _content = content ?? { path: id ? `/${id}/content-${new Date().toISOString()}.json` : undefined, container: "blog-posts" };
    
            _content.data = btoa(String.fromCharCode.apply(null, Array.from(gzip(JSON.stringify(convertToRaw(editorState.getCurrentContent())))))),
                _content.compressed=true,

                state.content = _content;
          
        });

    }, [editorState]);
    
    //useEffect(() => {
    //    setTimeout(() => {
    //        const content = editorState.getCurrentContent();
    //        const contentWithEntity = content.createEntity('separator', 'IMMUTABLE', {});
    //        const entityKey = contentWithEntity.getLastCreatedEntityKey();
           



    //        setState(AtomicBlockUtils.insertAtomicBlock(
    //            EditorState.push(editorState, contentWithEntity, 'create-entity' as EditorChangeType),
    //            entityKey,
    //            '-'
    //        ));
    //    }, 10000);
    //}, []);
    return <div className={editorClass}  >
        <Editor ref={editor} plugins={plugins} onTab={onTab}
            editorState={editorState}
            onChange={onChange}
            placeholder="Tell your story..."
            blockRendererFn={blockRenderer}
            blockRenderMap={RenderMap}
            blockStyleFn={blockStyleFn}
            spellCheck={true}
        />
        <AddButton sideButtons={defaultSideButtons}
            editorState={editorState}
            focus={onFocus}
            updateEditor={updateEditor}
            
        />
        <InlineToolbar />

    </div>
}



RegistereControl("BlogEditorControl", BlogEditorControl);