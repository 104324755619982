import { Block } from "./constants";

/*
Returns default block-level metadata for various block type. Empty object otherwise.
*/
export const getDefaultBlockData = (blockType: string, initialData = {}) => {
    switch (blockType) {
        case Block.TODO: return { checked: false };
        default: return initialData;
    }
};
