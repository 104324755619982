import { EditorState } from "draft-js";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import styles from "./AddButton.module.scss";


/*
Recursively finds the DOM Element of the block where the cursor is currently present.
If not found, returns null.
*/
export const getSelectedBlockNode = (root: Window) => {
    const selection = root.getSelection();
    if (!selection)
        return null;

    if (selection?.rangeCount === 0) {
        return null;
    }
    let node = selection?.getRangeAt(0).startContainer as HTMLElement;
    if (!node)
        return null;
    // console.log(node);
    do {
        if (node.getAttribute && node.getAttribute('data-block') === 'true') {
            return node;
        }
        node = node.parentNode as HTMLElement;
        // console.log(node);
    } while (node !== null);
    return null;
};


console.log("style", styles);
export type AddButtonProps = {
    focus: Function,
    editorState: EditorState;
    sideButtons: any[];
    updateEditor: (cb: (state: EditorState) => EditorState) => void;
}
export const AddButton: React.FC<AddButtonProps> = ({ focus, editorState, sideButtons, updateEditor }) => {

    const [style, setStyle] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const blockType = useRef('');
    const blockKey = useRef('');
    const node = useRef<HTMLElement>();
    const openToolbar = useCallback < React.MouseEventHandler < HTMLButtonElement>>((e) => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        const x = window.scrollX || window.pageXOffset;
        const y = window.scrollY || window.pageYOffset;
        // do focus
        focus();
        // back previous window state
        window.scrollTo(x, y);
    }, [isOpen]);

    const bntClassName = useMemo(() => `${styles.mdSBButton} ${styles.mdAddButton} ${isOpen ? styles.mdOpenButton : ''}`.trim(), [isOpen]);



    const hideBlock = useCallback(() => {
        if (isVisible) {
            setIsOpen(false);
            setIsVisible(false);
        }
    }, [isVisible]);



    const findNode = useCallback(() => {
        // eslint-disable-next-line no-undef
        const _node = getSelectedBlockNode(window);
        if (_node === node.current) {
            // console.log('Node exists');
            return;
        }
        if (!_node) {
            // console.log('no node');
            setIsOpen(false);
            setIsVisible(false);


            return;
        }
        // const rect = node.getBoundingClientRect();
        node.current = _node;
        setStyle({
            top: _node.offsetTop - 3,
        });
        setIsVisible(true);

    }, []);

 
    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const selectionState = editorState.getSelection();
        console.log(selectionState);
        //@ts-ignore
        if (!selectionState.isCollapsed() || selectionState.anchorKey !== selectionState.focusKey || contentState.getBlockForKey(selectionState.getAnchorKey()).getType().indexOf('atomic') >= 0) {
            // console.log('no sel');
            hideBlock();
            return;
        }

        //@ts-ignore
        const block = contentState.getBlockForKey(selectionState.anchorKey);
        const bkey = block.getKey();
        if (block.getLength() > 0) {
            hideBlock();
            return;
        }

        if (block.getType() !== blockType.current) {
            blockType.current = block.getType();
            if (block.getLength() === 0) {
               setTimeout(findNode, 0);
            }
            blockKey.current = bkey;
            return;
        }

        if (blockKey.current === bkey) {
            // console.log('block exists');
            if (block.getLength() > 0) {
                hideBlock();
            } else {
                setIsVisible(true);
            }
            return;
        }

        blockKey.current = bkey;
        if (block.getLength() > 0) {
            // console.log('no len');
            hideBlock();
            return;
        }
        setTimeout(findNode, 0);
    }, [editorState]);

    if (!isVisible)
        return null;
    
    return <div className={styles.mdSideToolbar} style={style}>
        <button
            onClick={openToolbar}
            className={bntClassName}
            type="button"
        >
            <svg viewBox="0 0 8 8" height="14" width="14">
                <path d="M3 0v3h-3v2h3v3h2v-3h3v-2h-3v-3h-2z" />
            </svg>
        </button>
        {isOpen && (
            <TransitionGroup>
                { sideButtons.map((button) => {
                    const Button = button.component;
                    const extraProps = button.props ? button.props : {};
                    return (
                        <CSSTransition
                            key={button.title}
                            classNames={styles.mdAddBtnAnim}
                            appear
                            timeout={{
                                enter: 200,
                                exit: 100,
                                appear: 100,
                            }}
                        >
                            <Button
                                {...extraProps}
                                updateEditor={updateEditor }
                                close={openToolbar}
                            />
                        </CSSTransition>
                    );
                })}
            </TransitionGroup>
        ) }
    </div>
}