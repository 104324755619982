

import { RegistereRibbonButton, SimpleDialog, useAppInfo, useFormChangeHandlerProvider, useModelDrivenApp, useModelDrivenViewContext, useRibbon, useSelectionContext } from "@eavfw/apps";

import { useBoolean } from "@fluentui/react-hooks";
import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { Input, ProgressBar, Field, Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Button, Spinner, makeStyles, Table, TableHeader, TableRow, TableHeaderCell, RadioGroup, Radio, TableBody, TableCell, TableCellLayout } from "@fluentui/react-components";
import { useSWRFetch } from "@eavfw/manifest";
import { WorkflowState } from "@eavfw/utils";

const useDialogStyles = makeStyles({
    root: {
        maxWidth:"90vw"
    }
})


RegistereRibbonButton("CUSTOM_MERGE", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const { selection, selectionDetails } = useSelectionContext();
    const app = useModelDrivenApp();
    //  const [isLoading, { toggle: startLoading }] = useBoolean(false);
    
    const { data, isLoading } = useSWRFetch<{ items: Array<any> }>(`/entities/${app.getEntity(currentEntityName).collectionSchemaName}?$filter=id in (${selection.getSelection().map(m => m.id)})`, !hideDialog);
    const styles = useDialogStyles();

    const { mutate: mutateView } = useModelDrivenViewContext();
    const { mutate: mutateForm } = useFormChangeHandlerProvider();
    const mutate = () => { mutateView(); mutateForm(); }

    const primaryField = useMemo(() => app.getPrimaryField(currentEntityName), [currentEntityName]);
    const attributes = useMemo(() => app.getAttributes(currentEntityName), [currentEntityName]);
    registerButton({
        key: key,
        text: props.text ?? "Merge",
        iconProps: props.iconProps ?? { iconName: 'Merge' },
        title: props.title ?? props.text ?? "Merge",
        disabled: false,
        onClick: toggleHideDialog
        //workflow: props.workflow
        // onClick: onClick
    });

    const [selectedRecord, setSelectedRecord] = useState();
    const [fieldSelected, setFieldSelected] = useState(() => Object.fromEntries( Object.entries(attributes).map(([k, v]) => [v.logicalName, null])));
     
    return (
        <Dialog open={!hideDialog}>
            <DialogSurface className={styles.root}>
                <DialogBody>
                    <DialogTitle>Merge {currentEntityName}</DialogTitle>
                    <DialogContent>                       
                        <Table arial-label="Merge Table">
                            <TableHeader>
                                <TableRow>
                                    
                                    <TableHeaderCell >
                                        Select Primary Record
                                    </TableHeaderCell>
                                        {data?.items.map(record => (
                                            <TableHeaderCell key={record.id}>

                                                <Radio onChange={(e, data) => {
                                                    setSelectedRecord(record.id);
                                                    //setFieldSelected(old => {

                                                    //    for (let attr of Object.keys(old)) {
                                                    //        if (old[attr] === null)
                                                    //            old[attr] = record.id;
                                                    //    }

                                                    //    return { ...old };

                                                    //});
                                                }} input={{ checked: selectedRecord === record.id }} value={record.id} label={record[primaryField]} />
                                        </TableHeaderCell>
                                    ))}
                                    
                                     
                                    
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {Object.entries(attributes).map(([k, attribute]) => (
                                    <TableRow key={k}>
                                     
                                        <TableCell>
                                            <TableCellLayout >
                                                {attribute.displayName}
                                            </TableCellLayout>
                                        </TableCell>
                                        {data?.items.map(record => (
                                            <TableCell key={record.id}>
                                                <TableCellLayout >
                                                    <Radio value={record.id} label={record[attribute.logicalName]}
                                                        onChange={(e, d) => {
                                                            setFieldSelected(old => {
                                                                old[attribute.logicalName] = record.id

                                                                return { ...old }
                                                            })
                                                        }}
                                                        input={{ checked: fieldSelected[attribute.logicalName] === record.id || (fieldSelected[attribute.logicalName] === null &&  selectedRecord === record.id)}}/>
                                                </TableCellLayout>
                                            </TableCell>
                                        ))}
                                            
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions fluid>
                        <Button
                            appearance="primary"
                            
                            onClick={async () => {


                                let rsp = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/workflows/MergeRecordsWorkflow/runs`, {
                                    method: "POST",
                                    body: JSON.stringify({
                                        entityName: app.getEntity(currentEntityName).collectionSchemaName,
                                        target: selectedRecord,
                                        sources: data?.items.map(record => record.id).filter(x => x !== selectedRecord),
                                        fields: fieldSelected
                                    }),
                                    credentials: "include"
                                });
                                let id = await rsp.json().then(x => x.id);

                                let completed = false;

                                while (!completed) {
                                    let statusRsp = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/workflows/MergeRecordsWorkflow/runs/${id}/status`, {
                                        credentials: "include"
                                    });

                                    let status = await statusRsp.json();
                                    completed = status.completed;


                                    await new Promise((resolve) => setTimeout(resolve, 1000));


                                }

                                let stateRsp = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/workflows/MergeRecordsWorkflow/runs/${id}`, {
                                    //headers: {
                                    //    'traceparent': `00-${spanContext.traceId}-${spanContext.spanId}-0${spanContext.traceFlags}`
                                    //},
                                    credentials: "include"
                                });

                                let result = await stateRsp.json() as WorkflowState;

                                if (result.status.toLowerCase() === "failed") {

                                    return;
                                }


                                mutate();
                                toggleHideDialog(); 

                            }}>
                            {isLoading ? <Spinner size="small" /> : 'Merge'}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
});