import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider } from "@eavfw/apps";
import { PlugDisconnected24Regular  } from "@fluentui/react-icons";
import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Suspense, useCallback } from 'react'

import { Dropdown, Option } from '@fluentui/react-components';
import { useState } from "react";
import { useJsonFetcher, useSWRFetch } from "@eavfw/manifest";
import { CompoundButton } from "@fluentui/react-components";
import { env } from "process";
import { useEAVForm } from "@eavfw/forms";
 
RegistereRibbonButton("CUSTOM_AUTHORIZE_PAYMENTPOINT", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const { mutate:mutateForm } = useFormChangeHandlerProvider();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [environment, setEnvironment] = useState("sandbox");
    const app = useModelDrivenApp();


    const { data, error, isLoading, mutate } = useSWRFetch(`/providers/PaymentProviders.MobilePay/paymentpoints/${currentRecordId}`);

    registerButton({
        key: key,
        text: "Authorize Payment Point",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Authorize Payment Point",
        disabled: false,
        onClick: () => {
            toggleHideDialog();
            
        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);
    const [baseUrl, jsonFetcher] = useJsonFetcher();
    const connect = useCallback(() => {
        fetch(`${baseUrl}/providers/PaymentProviders.MobilePay/paymentpoints/${currentRecordId}/connect`, {
            credentials: "include",
            method: "POST"
        })
            .then(() => {
                setTimeout(() => {
                    mutate(); mutateForm();
                }, 2500);
            });
    }, []);

    return <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>
        <pre>{JSON.stringify(data ?? {},null,4)}</pre>
        <Dropdown placeholder="Environment..." onOptionSelect={(e: any, data: any) => { console.log(data); console.log(e); if (data.optionValue) { setEnvironment(data.optionValue); } }} defaultValue={environment} >
            <Option>Sandbox</Option>
            <Option>Production</Option>
        </Dropdown>
        <CompoundButton
            icon={<PlugDisconnected24Regular />}
            secondaryContent="Connect Payment Point" onClick={connect}
        >
            Connect
        </CompoundButton>
      
    </SimpleDialog>

});