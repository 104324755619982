import { useCallback, useEffect, useRef, useState } from "react";

import Editor, { OnMount, useMonaco } from "@monaco-editor/react";
import type { editor } from 'monaco-editor';
import { useCustomerManifest } from "../Contexts/CustomerManifestDefinitionProvider";

export const ManifestSourceView: React.FC<{ className?: string }> = ({ className }) => {
    try {
        const { manifest } = useCustomerManifest();
        const [height, setHeight] = useState<number>(0);
        const data = JSON.stringify(manifest, null, 4);
        const editorRef = useRef<editor.IStandaloneCodeEditor>();
        const div = useCallback((node: HTMLDivElement) => { }, []);
        const handleEditorDidMount: OnMount = (editor, monaco) => {
            editorRef.current = editor;
            editor.onDidContentSizeChange(() => {
                setHeight(Math.max(100, editor.getContentHeight()));
             //   editor.layout();
            });
        };
        const monaco = useMonaco();
        useEffect(() => {
            if (monaco) {
                monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                    validate: true, allowComments: true,
                    //schemas: sourceViewSchema,
                    schemaValidation: "error",
                });
            }
        }, [monaco]);

        return (
            <div className="eavfw-codeeditor" style={{ width: "100%", height: "100%" }} ref={div}>
                <Editor className={className}
                    options={{
                        automaticLayout: true,
                        scrollBeyondLastLine: false
                    }}
                   // onChange={handleEditorOnChange}
                    onMount={handleEditorDidMount}
                    defaultLanguage="json"
                    value={data}
                />
            </div>
            
        );
    } finally {
        console.groupEnd();
    }
};
