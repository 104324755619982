import { makeResetStyles, mergeClasses } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const flatTreeClassNames = {
    root: 'fui-FlatTree'
};
const useBaseStyles = makeResetStyles({
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalXXS
});
export const useFlatTreeStyles_unstable = (state)=>{
    'use no memo';
    const baseStyles = useBaseStyles();
    state.root.className = mergeClasses(flatTreeClassNames.root, baseStyles, state.root.className);
    return state;
};
