import { ContentBlock, ContentState } from "draft-js";

import editorStyles from '../editorStyles.module.scss';

export const AtomicBlock: React.FC<{ contentState: ContentState, block: ContentBlock, blockProps: any, }> = ({ blockProps, block }) => {

    const content = blockProps.getEditorState().getCurrentContent();
    const entity = content.getEntity(block.getEntityAt(0));
    const data = entity.getData();
    const type = entity.getType();
    if (blockProps.components[type]) {
        const AtComponent = blockProps.components[type];
        return (
            <div className={`${editorStyles.mdBlockAtomicWrapper} mdBlockAtomicWrapper${type.charAt(0).toUpperCase() + type.slice(1)}`}>
                <AtComponent data={data} />
            </div>
        );
    }
    return <p>Block of type <b>{type}</b> is not supported.</p>;
};