import { RegistereControl, useAppInfo, useModelDrivenApp } from "@eavfw/apps";
import { classNames } from "@eavfw/designer-core";
import { getRecordSWR, IRecord, queryEntitySWR } from "@eavfw/manifest";
import { Button, Field, Input, makeStyles } from "@fluentui/react-components";


import {
    Tree,
    FlatTree,
    TreeProps,
    TreeItem,
    TreeItemLayout,
    useTree_unstable,
    useTreeStyles_unstable,
    useTreeContext_unstable,
    TreeProvider,
    TreeSlots,
    TreeNavigationData_unstable,
    TreeNavigationEvent_unstable,
    FlatTreeItemProps,
    useFlatTree_unstable,
    useHeadlessFlatTree_unstable,
    FlatTreeItem,
} from "@fluentui/react-components";
import {
    CalendarMonthRegular, Delete20Regular, LinkSquareRegular, LockClosedRegular, AddSquareRegular,
    WalletCreditCardRegular, AddSquareFilled, bundleIcon, iconFilledClassName, iconRegularClassName,
    FluentIconsProps, PeopleRegular, EditRegular,
    EditFilled, MoreHorizontalRegular,
    MoreHorizontalFilled,
} from "@fluentui/react-icons";
import { Dispatch, MouseEvent, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { DialogContent, DialogTitle, DialogActions, DialogTrigger, DialogSurface, Dialog, DialogBody } from '@fluentui/react-components'


import { useBoolean } from "@fluentui/react-hooks";
import { Dropdown, Option, Combobox } from "@fluentui/react-components";
import { error } from "console";

const iconStyleProps: FluentIconsProps = {
    primaryFill: 'purple',
    className: 'iconClass',
};

const useIconStyles = makeStyles({
    icon: {
        ':hover': {
            [`& .${iconFilledClassName}`]: {
                display: 'none',
            },
            [`& .${iconRegularClassName}`]: {
                display: 'inline',
            },
        },
    },
});

const AddSquare = bundleIcon(AddSquareFilled, AddSquareRegular);


const useStackStyles = makeStyles({

    stack: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
        '> *': {
            textOverflow: 'ellipsis',
        },
        '> :not(:first-child)': {
            marginTop: '0px',
        },
        '> *:not(.ms-StackItem)': {
            flexShrink: 1,
        },
        '&.horionzontal': {
            flexDirection: 'row',
        },
        '&.vertical': {
            flexDirection: 'column',
        },
        '&.fill': {
            width: '100%',
        }
    },

})

type BudgetItem = Partial<{
    id: string
    item: string;
    budgetid: string;
    budgetitemcategory: any;
    budgetitemcategoryid: string;
    amount: number;
    actualamount: number;
    estimatetocomplete: number;
    estimatetocompletion: number;
    costvariance: number;
    effectivedate: string
}>
type BudgetItemCategory = {
    id: string;
    type: string;
    parentcategoryid: string;
    totalamount?: number;
    childamounts?: any;
    title: string;
    [k:string]:any
}  

const newBudgetItemReducerFunc = (state: BudgetItem, action: BudgetItem | string) => {
    if (typeof (action) === "string")
        return JSON.parse(action);
    return { ...state, ...action };
}
const useNewBudgetItem = (budgetid: string, mutate: any) => {

    const app = useModelDrivenApp();

    const [selectedcategory, setselectedcategory] = useState<IRecord>();
    const [edit, setEdit] = useState<string>();

    const categorywithitems = getRecordSWR(app.getEntityFromKey("Budget Item Category").collectionSchemaName,
        edit!, `?$expand=budgetitems($filter=budgetid eq ${budgetid})`,
        typeof edit !== "undefined");


    const [item, dispatch] = useReducer(
        newBudgetItemReducerFunc,
        {
            budgetid
        }
    );

    useEffect(() => {
        console.log("Reset Values", [item, selectedcategory, categorywithitems?.record, edit]);
        if (edit && categorywithitems?.record) {

            setselectedcategory(categorywithitems?.record);

            if (categorywithitems?.record.budgetitems?.length === 1) {
                dispatch(categorywithitems?.record.budgetitems[0]);
            } else if (categorywithitems?.record.budgetitems?.length === 0) {
                dispatch({ item: categorywithitems?.record.title, budgetitemcategoryid: categorywithitems?.record.id });
            }
        } else {
            console.log("Reset Values");
            dispatch(JSON.stringify({
                budgetid
            }));
        }
    }, [selectedcategory, categorywithitems?.record, edit]);

    const create = useCallback((e: MouseEvent) => {



        /**
         * Do not change current categories, but instead create a new one.
         */
        if (item.item !== selectedcategory?.title) {

            delete item.budgetitemcategoryid
        }

        /**
         * Set the budgetitemcategory such its created for a new item.
         */
        item.budgetitemcategory = {
            id: item.budgetitemcategoryid,
            title: item.item,
            type: selectedcategory?.type,
            parentcategoryid: selectedcategory?.parentcategoryid ?? selectedcategory?.id
        };


        if (typeof item.amount === "number" && item.amount === 0) {

            let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/budgetitemcategories/records/`, {
                method: "POST",
                body: JSON.stringify(item.budgetitemcategory),
                credentials: "include"
            }).then((rsp) => {
                if (rsp.ok) {
                    mutate();
                    setselectedcategory(undefined);
                    setEdit(undefined);
                }
            })

            return;
        }


        let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/budgetitems/records/${item.id ?? ''}`, {
            method: item.id ? "PATCH" : "POST",
            body: JSON.stringify(item),
            credentials: "include"
        }).then((rsp) => {
            if (rsp.ok) {
                mutate();
                setselectedcategory(undefined);
                setEdit(undefined);
            }
        })
    }, [item]);


    return {
        item,
        setItem: dispatch,
        create,
        isDialogOpened: typeof (selectedcategory ?? edit) !== "undefined",
        startNew: setselectedcategory,
        startEdit: (categoryid: string) => {
            setEdit(categoryid);

        },
        clearSelected: () => {
            setEdit(undefined);
            setselectedcategory(undefined);
        }
    };// as [typeof item, typeof dispatch,typeof create];
}

import { DatePicker, DatePickerProps } from "@fluentui/react-datepicker-compat";

const useStyles = makeStyles({
    control: {
        maxWidth: "300px",
    },
});

const NewBudgetItemField = ({
    setItem, logicalName, label, autoFocus, data, tabindex,
}: { autoFocus?: boolean; tabindex?: number, setItem: Dispatch<BudgetItem>, label: string, logicalName: keyof BudgetItem, data: BudgetItem }) => {

    console.log("NewBudgetItemField", [data, logicalName]);
    //const ref = useRef < HTMLInputElement>(null);
    //useEffect(() => {
    //    if (ref.current && autoFocus) {
    //        ref.current.focus();
    //    }
    //}, [autoFocus])

    if (logicalName === "effectivedate") {
        const styles = useStyles();
        return (
            <Field label={label}>
                <DatePicker
                    className={styles.control}
                    placeholder="Select a date..." onSelectDate={date => { console.log(date); setItem({ [logicalName]: date?.toISOString() }) }}
                    onChange={(e, data) => { console.log(e); console.log(data); setItem({ [logicalName]: data.value }) }}
                />
            </Field>
        )
    }

    return (
        <Field label={label}>
            <Input tabIndex={tabindex} value={data?.[logicalName] ?? ''} autoFocus={autoFocus} onChange={(e, data) => setItem({ [logicalName]: data.value })} />
        </Field>
    )
}
// Create our number formatter.
const formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

console.log(formatter.format(2500)); /* $2,500.00 */


import { TableBody, TableCellActions, Table, TableCell, TableHeader, TableCellLayout, TableRow, TableHeaderCell, useTableFeatures, useTableColumnSizing_unstable, TableColumnSizingOptions, TableColumnDefinition, createTableColumn } from '@fluentui/react-components'
type Item = {
    title: string;
    amount: number;
    id: string;
    type: string;
    treeitem: any
};

const month = (f: any) => Array.from(Array(12), (e, i) => new Date(25e8 * ++i).toLocaleString('en-US', { month: f }));
const months = Array.from(Array(12).keys(), (i) => month`short`[i]);
const columnsDef: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
        columnId: "title",
        renderHeaderCell: () => <>Category</>,
    }),
    ...months.map(month =>
        createTableColumn<Item>({
            columnId: month,
            renderHeaderCell: () => <>{month}</>,
        })),
    createTableColumn<Item>({
        columnId: "amount",
        renderHeaderCell: () => <>Total</>,
    }),
];

const EditIcon = bundleIcon(EditFilled, EditRegular);
const MoreHorizontalIcon = bundleIcon(
    MoreHorizontalFilled,
    MoreHorizontalRegular
);

const BudgetViewer = () => {

    const { currentRecordId, currentEntityName, currentAppName, currentAreaName } = useAppInfo();
    const stackStyles = useStackStyles();


    const app = useModelDrivenApp();
   
    const categories = queryEntitySWR<BudgetItemCategory>(app.getEntityFromKey("Budget Item Category"));
    const items = queryEntitySWR(app.getEntityFromKey("Budget Item"), {
        $apply: `filter(budgetid eq ${currentRecordId} or budget/parentbudgetid eq ${currentRecordId})/compute(month(effectivedate) as month)/groupby((budgetitemcategoryid,month), aggregate(amount with sum as totalamount, actualamount with sum as totalacutalamount))`,
        //       $filter: `budgetid eq ${currentRecordId}`
    });

    const {
        item: budgetItem,
        setItem,
        create,
        isDialogOpened,
        startNew,
        startEdit,
        clearSelected
    } = useNewBudgetItem(currentRecordId, () => { categories.mutate(); items.mutate(); });



    const budgetCategories = useHeadlessFlatTree_unstable(useMemo(() => {

        let all = (categories?.data?.items ?? []).filter(x => x.parentcategoryid !== undefined);;
        let parents = (categories?.data?.items ?? []).filter(x => x.parentcategoryid === undefined).reverse();;

        while (all.length) {
            let first = all.shift();
            if (first) {
                let index = parents.findIndex(obj => obj.id === first!.parentcategoryid);
                if (index !== -1) {
                    parents.splice(index + 1, 0, first);
                } else {
                    all.push(first);
                }
            }
        }




        for (let b of categories?.data?.items ?? [])
            delete b.totalamount, delete b.childamounts, delete b.childamounts_group;

        let q = [] as Array<IRecord>;

        for (let b of items?.data?.items ?? []) {
           
            let cat = parents.find(x => x.id === b.budgetitemcategoryid);
          
            if (cat) {
                if (!cat.childamounts) {
                    cat.childamounts = {};
                }
                if (!cat.childamounts_group) {
                    cat.childamounts_group = {};
                }
                let k = `${cat.id}-${months[b.month - 1]}`;
                
               // let k1 = months[b.month - 1];
                cat.childamounts[k] = b.totalamount;
                cat.childamounts_group[k] = b.totalamount;
               
                cat.totalamount = Object.values<number>(cat.childamounts).reduce((a, b) => a + b, 0);
                console.log("Budget Item: " + cat.totalamount + "," + k, [b, cat]);
                q.push(cat);
            }

        }

        while (q.length) {
            let p = q.pop();
            if (p && p.parentcategoryid) {
                let cat = parents.find(x => p && x.id === p.parentcategoryid);
                if (cat) {
                    if (!cat.childamounts) {
                        cat.childamounts = {};
                    }
                    if (!cat.childamounts_group) {
                        cat.childamounts_group = {};
                    }
                    let k = `${p.id}-${months[p.month - 1]}`;
                   // let k1 = months[p.month - 1];
                    cat.childamounts[k] = p.totalamount;
                    cat.childamounts_group[k] = p.totalamount;
                    cat.childamounts_group = { ...cat.childamounts_group, ...p.childamounts_group }
                    cat.totalamount = Object.values<number>(cat.childamounts).reduce((a, b) => a + b, 0);
                    

                    q.push(cat);
                }
            }
        }


        let array2 = parents
            /**
             * Sort such category with no parent is first and if so sort by type (budget before income) 
             */
            //  ?.sort((a, b) => a.parentcategoryid === undefined ? b.parentcategoryid === undefined ? b.type - a.type : -1 : 1)
            ?.map(x => ({
                id: x.id,
                value: x.id,
                parentValue: x.parentcategoryid,
                // children: ,
            } as FlatTreeItemProps)) ?? [];

        console.log("Calculating Budget Categoreies", [categories?.data?.items, items?.data?.items, array2]);

        return array2;

    }, [categories?.data?.items, items?.data?.items, currentRecordId]), {
        defaultOpenItems: JSON.parse(sessionStorage.getItem(`${currentRecordId}_budget`) || '[]'),
        onOpenChange: (e, data) => {
            if (e.defaultPrevented)
                return;
            console.log("Open", [e, data]);
            let set = new Set(JSON.parse(sessionStorage.getItem(`${currentRecordId}_budget`) || '[]'));

            if (data.open)
                set.add(data.value);
            else
                set.delete(data.value);
            sessionStorage.setItem(`${currentRecordId}_budget`, JSON.stringify(Array.from(set.values())));
        }
    })


    console.log("budgetCategories", Array.from(budgetCategories.items()));
    console.log("items", items);


    const id = useMemo(() => currentRecordId + (budgetItem?.id ?? Math.random().toString()), [currentRecordId, budgetItem?.id]);

    const [columnSizingOptions] = useState<TableColumnSizingOptions>({
        title: {
            idealWidth: 300,
            minWidth: 300,
        },
        ...Object.fromEntries(months.map(m => [m, {
            minWidth: 60, 
            defaultWidth: 50,
        }])),
        amount: {
            idealWidth: 100,
            minWidth: 30,
        },
    });
    const [columns] = useState<TableColumnDefinition<Item>[]>(columnsDef);
    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
        {
            columns,
            items: Array.from(budgetCategories.items(), (props) => {
                let item = categories?.data?.items.find(x => x.id === props.value)!;
                console.log("Group " + item.title, item.childamounts_group);
                return {
                    ...item,
                    title: item.title,
                    groups:
                        Object.fromEntries(
                            months.map((m, i) => [m, Object.entries<number>(item.childamounts_group ?? {}).filter(([groupkey, value]) => groupkey.endsWith(m)).reduce((a, [_, b]) => a + b, 0)])),  //item.childamounts,
                 //  ...
                    amount: item.totalamount ?? 0,
                    treeitem: props
                } ;
            }),
        },
        [useTableColumnSizing_unstable({ columnSizingOptions })]
    );

    const [inputValue, setInputValue] = useState("300");

    const onWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        const numeric = parseInt(e.target.value, 10);
        if (!Number.isNaN(numeric)) {
            columnSizing_unstable.setColumnWidth("file", numeric);
        }
    };
    const rows = getRows();

    return (

        <div key={id} className={classNames(stackStyles.stack, 'fill')}>
            <Dialog open={isDialogOpened}><DialogSurface><DialogBody> <DialogTitle>Budget Linje</DialogTitle>
                <DialogContent  >
                    <NewBudgetItemField tabindex={1} label="Title" logicalName="item" autoFocus={typeof budgetItem?.item === 'undefined'} setItem={setItem} data={budgetItem} />
                    <NewBudgetItemField tabindex={2} label="Amount (u. moms)" autoFocus={typeof budgetItem?.item !== 'undefined'} logicalName="amount" setItem={setItem} data={budgetItem} />
                    <NewBudgetItemField label="Actual Amount (u. moms)" logicalName="actualamount" setItem={setItem} data={budgetItem} />
                    <NewBudgetItemField label="Estimate to Complete (u. moms)" logicalName="estimatetocomplete" setItem={setItem} data={budgetItem} />
                    <NewBudgetItemField label="Effective Date" logicalName="effectivedate" setItem={setItem} data={budgetItem} />
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary" onClick={() => { clearSelected(); }}>Close</Button>
                    </DialogTrigger>
                    <Button tabIndex={3} appearance="primary" onClick={create}>{budgetItem.id ? 'Update' : 'Create'}</Button>
                </DialogActions>
            </DialogBody></DialogSurface>
            </Dialog>

            <FlatTree  {...budgetCategories.getTreeProps()} aria-label="Tree">             
              


            </FlatTree>
            {/*<div>*/}
            {/*    table*/}
            {/*</div>*/}
        </div>

    )
}

RegistereControl("BudgetViewer", BudgetViewer);

//<Table sortable aria-label="Table with sort" ref={tableRef}>
//    <TableHeader>
//        <TableRow>
//            {columns.map((column) => (
//                <TableHeaderCell
//                    key={column.columnId}
//                    {...columnSizing_unstable.getTableHeaderCellProps(
//                        column.columnId
//                    )}
//                >
//                    {column.renderHeaderCell()}
//                </TableHeaderCell>
//            ))}
//        </TableRow>
//    </TableHeader>
//    <TableBody>

//        {rows.filter(x => x.item.treeitem.level === 1 || typeof x.item.totalamount !== "undefined").map(({ item: { treeitem: { value: id, level, getTreeItemProps, }, ...value } }) => {
//            const isUndeletable = level === 1;
//            //  const value = categories?.data?.items.find(x => x.id === id)!;
//            return (<TableRow>
//                <TableCell {...columnSizing_unstable.getTableCellProps("title")}>
//                    <TableCellLayout truncate style={{ width: "100%" }}>
//                        <TreeItem
//                            key={value.id}
//                            {...getTreeItemProps()}
//                            style={{ width: "100%" }}
//                        >
//                            <TreeItemLayout style={{ whiteSpace: "nowrap", textOverflow: 'ellipsis', width: "100%" }}
//                                iconBefore={<CalendarMonthRegular />}

//                                iconAfter={
//                                    <>
//                                        <Button appearance="subtle" onClick={(e) => { startNew({ id: value.id, type: value.type }); e.preventDefault(); }} size="small" icon={<AddSquareRegular aria-label="Add Category" />} />
//                                        <LockClosedRegular />

//                                        <Button appearance="subtle" onClick={(e) => { location.href = app.recordUrl({ entityName: "budgetitemcategory", appName: currentAppName, areaName: currentAreaName, recordId: id }); e.preventDefault(); }} size="small" icon={<LinkSquareRegular aria-label="Open Category" />} />


//                                    </>
//                                }>

//                                <span>{value.title}</span>

//                            </TreeItemLayout>
//                            {/*<TreeItemAside actions style={{ borderBottom: "solid 1px black", }}>*/}

//                            {/*    {*/}
//                            {/*        isUndeletable ? null : (*/}
//                            {/*            <>*/}
//                            {/*                <Button appearance="subtle"*/}
//                            {/*                    onClick={() => { startEdit(id) }}*/}
//                            {/*                    size="small"*/}
//                            {/*                    icon={<WalletCreditCardRegular aria-label="Edit line" />} />*/}
//                            {/*                <Button*/}
//                            {/*                    aria-label="Remove item"*/}
//                            {/*                    appearance="subtle"*/}
//                            {/*                    onClick={(e) => console.log(e)}*/}
//                            {/*                    icon={<Delete20Regular />}*/}
//                            {/*                />*/}
//                            {/*            </>*/}
//                            {/*        )*/}
//                            {/*    }*/}
//                            {/*</TreeItemAside>*/}


//                        </TreeItem>
//                    </TableCellLayout>
//                </TableCell>
//                {months.map(m => <TableCell key={m}
//                    {...columnSizing_unstable.getTableCellProps(m)}
//                >
//                    <TableCellLayout truncate>
//                        {formatter.format(value.groups[m] ?? 0)}
//                    </TableCellLayout>

//                </TableCell>
//                )}
//                <TableCell
//                    {...columnSizing_unstable.getTableCellProps('amount')}
//                >
//                    <TableCellLayout truncate>
//                        {formatter.format(value.totalamount ?? 0)}

//                    </TableCellLayout>
//                    <TableCellActions>
//                        <Button onClick={() => { startEdit(id) }} icon={<EditIcon />} appearance="subtle" />
//                        <Button icon={<MoreHorizontalIcon />} onClick={(e) => { location.href = app.recordUrl({ entityName: "budgetitemcategory", appName: currentAppName, areaName: currentAreaName, recordId: id }); }} appearance="subtle" />
//                    </TableCellActions>
//                </TableCell>
//            </TableRow>
//            );
//        })}

//    </TableBody>

//</Table>