import { RegistereView, useModelDrivenApp } from "@eavfw/apps"
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, Sidebar, Search, ConversationList, Avatar, Conversation, ConversationHeader, VoiceCallButton, VideoCallButton, InfoButton, TypingIndicator, MessageSeparator, MessageType } from '@chatscope/chat-ui-kit-react';
import { getRecordCount, getRecordSWR, queryEntitySWR, useSWRFetch } from "@eavfw/manifest";
import { useCallback, useMemo, useState } from "react";

type ConversationsType = {
    items: Array<{
        createdon: string,
        deliveredtime: string,
        smsmessages: number;
        customer?: { user: { id: string, name: string, phone: string } }
        recipient?: { value: string }
    }>
}
type MessagesType = {
    items: Array<{
        deliverytime: string;
        id: string,
        message: string,
        createdon: string,
        modifiedon: string,
status:number,
}>
}

export const SMSCommunicationOverview = () => {

    const app = useModelDrivenApp();
    const smsmessage = app.getEntity("smsmessage");
    const { data } = queryEntitySWR(app.getEntity("smsmessage"));

    const [active, setActive] = useState<string>();
    const [filter, setFilter] = useState<string>();
    const { count: smsCount } = getRecordCount("smsmessages", undefined, 10000);
    
    console.log(smsCount);
    console.log(data);

    let messageQuery = `/entities/smsmessages?$apply=filter(customer/user/id ne null${((filter?.length ?? 0) > 0 ? ` and  contains(customer/user/name,'${filter}') ` : '')})/groupby((Customer/User/id,Customer/User/Name,Customer/User/Phone),aggregate($count as smsmessages,createdon with max as createdon, deliveredtime with max as deliveredtime ))`;
    let messagesQuery = `/entities/smsmessages?$orderby=modifiedon&$filter=customer/user/id eq ${active}`;
    if (!("Customer" in smsmessage.attributes)) {
        messageQuery = `/entities/smsmessages?$apply=filter(recipient ne null${((filter?.length ?? 0) > 0 ? ` and  contains(recipient/value,'${filter}') ` : '')})/groupby((recipient/value),aggregate($count as smsmessages,createdon with max as createdon, deliveredtime with max as deliveredtime ))`;
        messagesQuery = `/entities/smsmessages?$orderby=modifiedon&$filter=recipient/value eq ${active}`;
    }

    const { data: usersdata } = useSWRFetch<ConversationsType>(messageQuery, undefined, 30000);
    const users = useMemo(() => usersdata?.items.filter(u => u.customer?.user?.id ?? u.recipient?.value).sort((a, b) => new Date(b.createdon).getTime() - new Date(a.createdon).getTime()), [usersdata?.items])
    const { data: messages } = useSWRFetch<MessagesType>(messagesQuery, typeof (active) !== "undefined", 10000);
    console.log(messages);

    const { record: user } = getRecordSWR("users", active!, "?$select=name,id", typeof active !== "undefined");

    const sendSms = useCallback((innerHtml: string, textContent: string, innerText: string, nodes: NodeList) => {
       // .replace(/<br\s*\\?>/g, "\r\n")
        console.log(user);
        console.log(innerText);


        let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/smsmessages/records`, {
            method: "POST",
            body: JSON.stringify({
                message: innerText,
                customerid: active,
                status: 20, //pending
                variables: JSON.stringify({ "reason": "fromui" }),
                providerid: 'FBA9456E-5366-40B7-5202-08DAC64EC120',

            }),
            credentials: "include"
        });

    }, [user]);
    return (<MainContainer responsive>
        <Sidebar position="left" scrollable={true} >
            <Search placeholder="Search..." onChange={setFilter} />
            <ConversationList scrollable>
                {users?.map(u => {
                    const key = u.customer?.user?.id ?? u.recipient?.value;
                    const displayName = u.customer?.user.name ?? u.recipient?.value;
                    return <Conversation key={key} active={key === active} onClick={(e) => setActive(key)} unreadDot unreadCnt={u.smsmessages} name={`${displayName} (${u.customer?.user?.phone ?? u.recipient?.value})`} lastSenderName={displayName} info={u.createdon}></Conversation>
                }
                ) }
            </ConversationList>
        </Sidebar>    
        <ChatContainer>
            <ConversationHeader>
                <ConversationHeader.Back />
                <Avatar name={user?.name} />
                <ConversationHeader.Content userName={user?.name} info={`Last sms: ${messages?.items[messages?.items?.length - 1]?.modifiedon}`} />
            <ConversationHeader.Actions>
                {/*<VoiceCallButton />*/}
                {/*<VideoCallButton />*/}
                {/*<InfoButton />*/}
            </ConversationHeader.Actions>
        </ConversationHeader>
            
            <MessageList> {/* typingIndicator={<TypingIndicator content="Zoe is typing" />}*/ }

                {messages?.items.map(m => <Message key={m.id} model={{
                    message: m.message,
                    sentTime: m.deliverytime ?? m.createdon,
                    sender: "1416",
                    direction: m.status === 100 ? "incoming" : "outgoing",
                    position: "single"
                }}></Message>)}

                    {/*<MessageSeparator >*/}
                    {/*    Saturday, 30 November 2019</MessageSeparator>*/}
 
            </MessageList>
            <MessageInput placeholder="Type message here" onSend={sendSms} />
        </ChatContainer>
    </MainContainer>);
}

RegistereView("SMSCommunicationOverview", SMSCommunicationOverview);