import React, { createContext, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Calendar, Views, DateLocalizer, momentLocalizer, View, DateRangeFormatFunction } from 'react-big-calendar'
import { RegistereControl, RegistereRibbonButton, SimpleDialog, useAppInfo, useFormChangeHandlerProvider, useModelDrivenApp, useRibbon } from '@eavfw/apps'
import moment from 'moment'
import { queryEntity, queryEntitySWR, getRecordSWR, IRecord } from '@eavfw/manifest'
import { useBoolean } from '@fluentui/react-hooks'
import { createTableColumn, Table, TableBody, TableCell, TableColumnDefinition, TableHeader, TableHeaderCell, TableRow, TableSelectionCell, useArrowNavigationGroup, useTableFeatures, useTableSelection, Portal } from '@fluentui/react-components'
import { DialogContent, DialogTitle, DialogActions, DialogTrigger, DialogSurface, Dialog, DialogBody, Button } from '@fluentui/react-components'
import { Dropdown, Option } from "@fluentui/react-components";
import { useEAVForm } from '@eavfw/forms'

function sfc32(a:any, b:any, c:any, d:any) {
    return function () {
        a >>>= 0; b >>>= 0; c >>>= 0; d >>>= 0;
        var t = (a + b) | 0;
        a = b ^ b >>> 9;
        b = c + (c << 3) | 0;
        c = (c << 21 | c >>> 11);
        d = d + 1 | 0;
        t = t + d | 0;
        c = c + t | 0;
        return (t >>> 0) / 4294967296;
    }
}

function cyrb128(str: string) {
    let h1 = 1779033703, h2 = 3144134277,
        h3 = 1013904242, h4 = 2773480762;
    for (let i = 0, k; i < str.length; i++) {
        k = str.charCodeAt(i);
        h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
        h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
        h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
        h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
    }
    h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
    h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
    h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
    h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
    return [(h1 ^ h2 ^ h3 ^ h4) >>> 0, (h2 ^ h1) >>> 0, (h3 ^ h1) >>> 0, (h4 ^ h1) >>> 0];
}

//https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript

var seed = cyrb128("apples");
var rand = sfc32(seed[0], seed[1], seed[2], seed[3]);
function shuffle<T>(array: Array<T>) {

   
    

    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(rand() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

const colorpalete = shuffle([

    ['#ea002b', '#f0493f', '#f56e52', '#f88d64', '#f9aa73', '#f9c67e', '#f7e17e', '#f3ff2c'],
    ['#dd00a9', '#e0529c', '#e2788e', '#e3987f', '#e2b56e', '#e1d05a', '#e2ea42', '#f3ff2c'],
    ['#00429d', '#325da9', '#4e78b5', '#6694c1', '#80b1cc', '#9dced6', '#c0eade', '#ffffe0']
].flat());
const localizer = momentLocalizer(moment);

//const matches = [
//    { id:1, name: "Kamp 1"},                 //Quarter
//    { id: 2, name: "Kamp 2" },               //Quarter
//    { id: 3, name: "Kamp 3" },               //Quarter
//    { id: 4, name: "Kamp 4" },               //Quarter
//    { id: 5, name: "Kamp 5", win:[1,2] },    //Semi
//    { id: 6, name: "Kamp 6", win:[3,4] },    //Semi
//    { id: 7, name: "Kamp 7", win:[5,6] },    //1-2
//    { id: 8, name: "Kamp 8", los:[5,6] },    //3-4
//    { id: 9, name: "Kamp 9", los: [1, 2] },  //5-8
//    { id: 10, name: "Kamp 10", los:[3,4] },  //5-8
//    { id: 11, name: "Kamp 11", win:[9,10] }, //5-6
//    { id: 12, name: "Kamp 12" , los:[9,10]}, //7,8
//];

var start = new Date(2022, 11, 24, 9, 0, 0);



//const events = [
//    {
//        id: 0,
//        title: 'Kamp 1',
//        start: new Date(2022, 11, 24, 9, 0, 0),
//        end: new Date(2022, 11, 24, 13, 0, 0),
//        resourceId: 1,
//    },
//    {
//        id: 2,
//        title: 'Kamp 3',
//        start: new Date(2022, 11, 24, 8, 30, 0),
//        end: new Date(2022, 11, 24, 12, 30, 0),
//        resourceId: 3,
//    },
//    {
//        id: 11,
//        title: 'Kamp 4',
//        start: new Date(2022, 11, 24, 7, 0, 0),
//        end: new Date(2022, 11, 24, 10, 30, 0),
//        resourceId: 4,
//    },
//    {
//        id: 5,
//        title: 'Kamp 5',
//        start: new Date(2022, 11, 24, 7, 0, 0),
//        end: new Date(2022, 11, 24, 10, 30, 0),
//        resourceId: 5,
//    },
//]

//const resourceMap = [
//    { resourceId: 1, resourceTitle: 'Bane 1' },
//    { resourceId: 2, resourceTitle: 'Bane 2' },
//    { resourceId: 3, resourceTitle: 'Bane 3' },
//    { resourceId: 4, resourceTitle: 'Bane 4' },
//    { resourceId: 5, resourceTitle: 'Bane 5' },
//    { resourceId: 6, resourceTitle: 'Bane 6' },
//    { resourceId: 7, resourceTitle: 'Bane 7' },
//    { resourceId: 8, resourceTitle: 'Bane 8' },
//]

//Calendar.momentLocalizer(moment)
type matchType = {teama:[number,number], teamb:[number,number]}
function match(a: number, b: number, c: number, d: number): matchType {
    return { teama: [a, b], teamb: [c, d] };
}

//function perm(xs:number[]) : number[][]{
//    let ret = [];

//    for (let i = 0; i < xs.length; i = i + 1) {
//        let rest = perm(xs.slice(0, i).concat(xs.slice(i + 1)));

//        if (!rest.length) {
//            ret.push([xs[i]])
//        } else {
//            for (let j = 0; j < rest.length; j = j + 1) {
//                ret.push([xs[i]].concat(rest[j]))
//            }
//        }
//    }
//    return ret;

//}

//function hasplayedagainst(p1: number, other: number, round: number[]) {

//  //  console.log(`Americano8: checking ${p1} played against round (${round.join(',')})`);

//    return ((round[0] === p1 || round[1] == p1) && (round[2] === other || round[3] === other))
//        || ((round[0] === other || round[1] == other) && (round[2] === p1 || round[3] === p1))
//        || ((round[4] === p1 || round[5] == p1) && (round[6] === other || round[7] === other))
//        || ((round[4] === other || round[5] == other) && (round[6] === p1 || round[7] === p1))
//}
//function hasplayedwith(p1: number, other: number, round: number[]) {
//    return (round[0] === p1 || round[1] == other) || (round[2] === p1 || round[3] === other);
//}
//function gen(rounds: number[][]) : number[] {

//    //Picking random player 0
//    let p0 = Math.floor(rand() * 8);


//        //Remaining players
//        let remainingplayers = [...Array(8).keys()].filter(x => x !== p0);

//        //Picking random player 4, a player that p0 already played against
//        //let p0against = remainingplayers.filter(other => rounds.some(round => hasplayedagainst(p0, other, round)));
//        // console.log(`Americano8: has ${p0} played against (${p0against.join(',')})`)
//        let p4 = shuffle(remainingplayers.filter(other => rounds.some(round => hasplayedagainst(p0, other, round))))[0];
//        remainingplayers = remainingplayers.filter(x => x !== p4);

//        //Picking p0 first opponent p2, that not played against
//        let p2 = shuffle(remainingplayers.filter(other => !rounds.some(round => hasplayedagainst(p0, other, round))))[0];
//        remainingplayers = remainingplayers.filter(x => x !== p2);

//        //picking p4 first opponent p6, that not played against
//        let p6 = shuffle(remainingplayers.filter(other => !rounds.some(round => hasplayedagainst(p4, other, round))))[0];
//        remainingplayers = remainingplayers.filter(x => x !== p6);

//        let p1 = remainingplayers.pop();
//        let p3 = remainingplayers.pop();
//        let p5 = remainingplayers.pop();
//        let p7 = remainingplayers.pop();

//        let result = [p0, p1, p2, p3, p4, p5, p6, p7];

//            return result as number[];



//    //let m2 = shuffle(othersnotmeetyet).slice(0,2);
//}
//const plan = [shuffle([0, 1, 2, 3, 4, 5, 6, 7])];

//plan.push(gen(plan));
//plan.push(gen(plan));
//plan.push(gen(plan));
//plan.push(gen(plan));
//plan.push(gen(plan));
//plan.push(gen(plan));

////const plan = [r1, r2,r3,r4];



//const americano8a = [
//    [match(0, 6, 3, 7), match(4, 5, 1, 2)],
//    [match(6, 7, 3, 4), match(0, 1, 2, 5)],
//    [match(4, 6, 1, 7), match(3, 5, 0, 2)],
//    [match(1, 4, 5, 6), match(0, 7, 2, 3)],
//    [match(1, 5, 0, 4), match(3, 6, 2, 7)],
//    [match(0, 5, 1, 3), match(4, 7, 2, 6)],
//    [match(0, 3, 5, 7), match(1, 6, 2, 4)]
//]
//const americano8b = [
//    [match(1,2,0,3) ,match(6,7,4,5)],
//    [match(1,0,6,5),match(7,4,3,2)],
//    [match(1,6,7,2),match(4,3,5,0)],
//    [match(1,7,4,0),match(3,5,2,6)],
//    [match(1,4,3,6),match(5,2,0,7)],
//    [match(1,3,5,7),match(2,0,6,4)],
//    [match(1,5,2,4),match(0,6,7,3)]
//]

const americano4 = [
    match(3, 0,1,2),
    match(3, 1, 0,2),
    match(3, 2,0,1)
]
const armericano5 = [
    match(4, 0,1,2),
    match(4, 1,2,3),
    match(4, 2,3,0),
    match(4, 3, 0, 1),
    match(1,3, 0, 2),
]

const americano8 = [
    [match(7, 0, 1, 5), match(4, 6, 2, 3)],
    [match(7, 1, 2, 6), match(5, 0, 3, 4)],

    [match(7, 2, 3, 0), match(6, 1, 4, 5)],
    [match(7, 3, 4, 1), match(0, 2, 5, 6)],
    [match(7, 4, 5, 2), match(1, 3, 6, 0)],
    [match(7, 5, 6, 3), match(2, 4, 0, 1)],
    [match(7, 6, 0, 4), match(3, 5, 1, 2)]
]
//const americano12 = [
//    [match(7, 0, 1, 10), match(4, 11, 6, 8), match(2, 5, 3,  9)],
//    [match(7, 1, 2, 11), match(5, 0, 8, 9), match(3, 6, 4,  10)],
//    [match(7, 2, 3, 0), match(6, 1, 9, 10), match(4, 8, 5,  11)],
//    [match(7, 3, 4, 1), match(8, 2, 10, 11), match(5, 9, 6, 0)],
//    [match(7, 4, 5, 2), match(9, 3, 11, 0), match(6, 10, 8,  1)],
//    [match(7, 5, 6, 3), match(10, 4, 0, 1), match(8, 11, 9,  2)],
//    [match(7, 6, 8, 4), match(11, 5, 1, 2), match(9, 0, 10, 3)],

//    [match(7, 8, 9, 5), match(0, 6, 2, 3), match(10, 1, 11,  4)],
//    [match(7, 9, 10, 6), match(1, 8, 3, 4), match(11, 2, 0,  5)],
//    [match(7, 10, 11, 8), match(2, 9, 4, 5), match(0, 3, 1, 6)],
//    [match(7, 11, 0, 9), match(3, 10, 5, 6), match(1, 4, 2,  8)]
//]
const americano12 = [
    [match(3, 6, 8, 0),  match(10, 2, 11, 1), match(7, 9, 5, 4)], //K3 K4
    [match(3, 7, 6, 10), match(4, 11, 0, 2), match(5, 9, 1, 8)],
    [match(3, 8, 1, 4),  match(0, 5, 9, 11), match(6, 2, 10, 7)],
    [match(3, 9, 2, 8), match(6, 4, 10, 5), match(11, 0, 7, 1)],

    [match(3, 10, 0, 9), match(11, 8, 7, 4), match(5, 6, 1, 2)],
    [match(3, 11, 9, 6), match(7, 0, 5, 2), match(1,10,4,8)],
    [match(3, 0, 4, 2),  match(9, 1, 6, 7), match(10, 8, 11, 5)],
    [match(3, 1, 11, 7), match(4, 10, 2, 9), match(8, 5, 0, 6)],
    [match(3, 2, 5, 1),  match(8, 7, 0, 10), match(9, 4, 6, 11)],
    [match(3, 4, 7, 5),  match(2, 11, 8, 6), match(0, 1, 9, 10)],
    [match(3, 5, 10, 11), match(1, 6, 4, 0), match(2, 7, 8, 9)], 
]
//for (let j = 0; j < americano12.length; j++) {
//    for (let i = 0; i < americano12[j].length; i++) {
//        americano12[j][i].teama[0] += 8;
//        americano12[j][i].teama[1] += 8;
//        americano12[j][i].teamb[0] += 8;
//        americano12[j][i].teamb[1] += 8;
//    }
//}
//Take A8 for 2slots, 2 left.
//no A8, Find A12 x2
//const dist = [
//    4, 4, 4, 4,
//    1, 1, 1, 1, 4, 4, 3, 3, 2, 2, 1, 1];
//const americano12_2 = [

//]
//function overlap(a: matchType[], b: matchType[]) {

//    let p0 = a.map(b => b.teama.concat(b.teamb)).flat(); //All Players already in round
//    let p1 = b.map(b => b.teama.concat(b.teamb)).flat()
//    return new Set(p0.concat(p1)).size !== (p0.length + p1.length)

    
//}
//function overlaps(a: matchType[], b: matchType) {
//    let r = new Set(a.map(b => b.teama.concat(b.teamb)).flat());
//    let p = new Set(b.teama.concat(b.teamb));
//    return new Set(a.map(b => b.teama.concat(b.teamb)).flat().concat(b.teama.concat(b.teamb))).size !== (r.size + p.size);
//}
//let classes = [americano12, americano8];
//function returnMatches(classes: matchType[][][], slots: number, round: matchType[]) {
//    classes = classes.sort((a, b) => a[0].length - b[0].length);
//    console.log("Americano Scheduling:", [classes,round]);
//    let matches = classes.find(roundmatches => !overlap(round, roundmatches[0]))! // classes[0];        
//    if (matches[0].length <= slots) {
//        return matches.shift()!;
//    }

//    //More matches in next round then slots for, lets return slots amount of the round and sort the remaining
//    //such it it can be returned in next round
   

//    for (let j = 1; j < matches.length; j++) {
//        let a = matches[0].filter(c => overlaps(matches[j], c));
//        if (a.length >= slots) {

//        }
//    }




//    return [];
//}

//function print(m: matchType) {
//    return m.teama.join(" ") + " vs " +m.teamb.join(" ");
//}

//let currentRound = [] as matchType[];
////Step 1
//{
//    let m1 = returnMatches(classes, dist[0], currentRound);
//    if (!m1.length) {
//        m1 = returnMatches(classes, dist[0] + dist[1], currentRound);
//        console.log("Americano Scheduling:", [m1?.length, m1, m1?.map(print), classes, dist[0]]);
//    }

//    if (m1.length)
//        currentRound.push(...m1);

//    if (m1?.length > dist[0]) {
//        let r = dist.shift()!;
//        dist[0] -= (m1.length - r);
//    } else if (m1.length === dist[0])
//        dist.shift();
//    else
//        dist[0] -= m1?.length ?? 0;

//    console.log("Americano Scheduling:", [m1?.length, m1, m1?.map(print), classes, dist[0]])
//}
////Step 2
//{
//    let m1 = returnMatches(classes, dist[0], currentRound);
//    if (!m1.length) {
//        m1 = returnMatches(classes, dist[0] + dist[1], currentRound);
//        console.log("Americano Scheduling:", [m1?.length, m1, m1?.map(print), classes, dist[0]]);
//    }

//    if (m1)
//        currentRound.push(...m1);

//    if (m1?.length > dist[0]) {
//        let r = dist.shift()!;
//        currentRound = [];
//        dist[0] -= (m1.length - r); 
//    } else if (m1.length === dist[0])
//        dist.shift();
//    else
//        dist[0] -= m1?.length ?? 0;

//    console.log("Americano Scheduling:", [m1?.length, m1, m1?.map(print), classes, currentRound, dist[0]])

//}
////step 3
//{
//    let m1 = returnMatches(classes, dist[0], currentRound);
   
//    if (!m1.length) {
//        m1 = returnMatches(classes, dist[0] + dist[1], currentRound);
       
//    }

//    if (m1)
//        currentRound.push(...m1);

//    if (m1?.length > dist[0]) {
//        let r = dist.shift()!;
//        currentRound = [];
//        dist[0] -= (m1.length - r);
//    } else if (m1.length === dist[0]) {
//        dist.shift();
//        currentRound = [];
//    }
//    else
//        dist[0] -= m1?.length ?? 0;

//    console.log("Americano Scheduling:", [m1?.length, m1, m1?.map(print), classes, dist[0]])

//}
////step 4
//{
//    let m1 = returnMatches(classes, dist[0], currentRound);

//    if (!m1.length) {
//        m1 = returnMatches(classes, dist[0] + dist[1], currentRound);

//    }

//    if (m1)
//        currentRound.push(...m1);

//    if (m1?.length > dist[0]) {
//        let r = dist.shift()!;
//        currentRound = [];
//        dist[0] -= (m1.length - r);
//    } else if (m1.length === dist[0]) {
//        dist.shift();
//        currentRound = [];
//    }
//    else
//        dist[0] -= m1?.length ?? 0;

//    console.log("Americano Scheduling:", [m1?.length, m1, m1?.map(print), classes, dist[0]])

//}

//for (let j = 1; j < americano12.length; j++) {
//    let r0 = americano12[j - 1];
//}

//const americano12a = [
//    [match(3, 6, 8, 0), match(10, 2, 11, 1), match(7, 9, 5, 4)],
//    [match(3, 7, 6, 10), match(4, 11, 0, 2), match(5, 9, 1, 8)],
//    [match(3, 8, 1, 4), match(0, 5, 9, 11), match(6, 2, 10, 7)],
//    [match(3, 9, 2, 8), match(6, 4, 10, 5), match(11, 0, 7, 1)],
//    [match(3, 10, 0, 9), match(11, 8, 7, 4), match(5, 6, 1, 2)],
//    [match(3, 11, 9, 6), match(7, 0, 5, 2), match(1, 10, 4, 8)],
//    [match(3, 0, 4, 2), match(9, 1, 6, 7), match(10, 8, 11, 5)],
//    [match(3, 1, 11, 7), match(4, 10, 2, 9), match(8, 5, 0, 6)],
//    [match(3, 2, 5, 1), match(8, 7, 0, 10), match(9, 4, 6, 11)],
//    [match(3, 4, 7, 5), match(2, 11, 8, 6), match(0, 1, 9, 10)],
//    [match(3, 5, 10, 11), match(1, 6, 4, 0), match(2, 7, 8, 9)],
//]
const players8 = [0, 1, 2, 3, 4, 5, 6, 7];
const players12 = [0, 1, 2, 3, 4, 5, 6, 7,8,9,10,11];

//function findone(teams: number[][], ...excludes: number[][]) {
//    let a = teams.findIndex(x => !excludes.some(team => (team[0] === x[0] || team[1] === x[0] || team[0] === x[1] || team[1] === x[1])));
//    console.log("Americano8", a);
//    return teams.splice(a, 1)[0];
//}
//function addRound(rounds: number[][], teams: number[][]) {
//    const m1a = teams.pop()!;
//    const m1b = findone(teams, m1a);
//    const m2a = findone(teams, m1a, m1b);
//    const m2b = findone(teams, m1a, m1b,m2a);
//    rounds[rounds.length] = [m1a, m1b, m2a, m2b].flat()
//}     

//const teams = shuffle(players8.map(p => players8.filter(pp => pp > p).map(pp => [p, pp])).flat());
//const rounds = [] as number[][] ;

 
//addRound(rounds, teams);
//addRound(rounds, teams);
//addRound(rounds, teams);
//addRound(rounds, teams);
//addRound(rounds, teams);
//addRound(rounds, teams);
//addRound(rounds, teams);

//const americano8e = rounds.map(x => [match(x[0], x[1], x[2], x[3]), match(x[4], x[5], x[6], x[7])])

////console.log("Americano8", );

//const combinations = perm(players8);

const matchesPerPlayer8 = Object.fromEntries(
    players8.map(x => [x, americano8.flat().filter(match => match.teama.some(xx => xx === x) || match.teamb.some(xx => xx === x))]));

const matchesPerPlayer12 = Object.fromEntries(
    players12.map(x => [x, americano12.flat().filter(match => match.teama.some(xx => xx === x) || match.teamb.some(xx => xx === x))]));

function isplayingagainst(p1: number, p2: number, match: matchType) {
    return (match.teama.some(xx => xx === p1) && match.teamb.some(xx => xx === p2)) || (match.teama.some(xx => xx === p2) && match.teamb.some(xx => xx === p1));

} 
function isplayingwith(p1: number, p2: number, match: matchType) {
    return (match.teama.some(xx => xx === p1) && match.teama.some(xx => xx === p2)) || (match.teamb.some(xx => xx === p2) && match.teamb.some(xx => xx === p1));

} 

console.log("Americano8", {
  //  plan: plan,
  //  perms:perm(players8).length,
  //  teams,
  //  rounds,
    americano8,
    players8,
    matchesPerPlayer8,
    playervsplayer: Object.fromEntries(players8.map(p => players8.filter(pp => pp > p).map(pp => [p, pp]))
        .flat().map(pvsp => [pvsp.join('vs'),[
            americano8.flat().filter(match => isplayingagainst(pvsp[0], pvsp[1], match)).length,
            americano8.flat().filter(match => isplayingwith(pvsp[0], pvsp[1], match)).length]]))
});
console.log("Americano12", {
    //  plan: plan,
    //  perms:perm(players8).length,
    //  teams,
    //  rounds,
    americano12,
    players12,
    matchesPerPlayer12,
    playervsplayer: Object.fromEntries(players12.map(p => players12.filter(pp => pp > p).map(pp => [p, pp]))
        .flat().map(pvsp => [pvsp.join('vs'), [
            americano12.flat().filter(match => isplayingagainst(pvsp[0], pvsp[1], match)).length,
            americano12.flat().filter(match => isplayingwith(pvsp[0], pvsp[1], match)).length]]))
});


export const useStartTime = (info: any) => {
    const startTime = useMemo(() => {
        if (typeof info.record?.starttime === "undefined")
            return undefined;

        return new Date(info.record?.starttime);


    }, [info.record?.starttime]);

    return startTime;
}
export const useEndTime = (info: any) => {
    const endTime = useMemo(() => {
        if (typeof info.record?.endtime === "undefined")
            return undefined;

        return new Date(info.record?.endtime);


    }, [info.record?.endtime]);
    return endTime;
}


const EventContainerWrapperView = (props: any, a: any) => {
    useEffect(() => {
        console.log("EventContainerWrapperView", [props, a]);
    },[]);
    return <div>{props.children}</div>
}

const EventWrapperView = (props: any, a: any) => {

    useEffect(() => {
        console.log("EventWrapperView", [props, a]);
    }, []);
    return <div>{props.children}</div>;
}
const EventView = (props: any, a: any) => {


    useEffect(() => {

        console.log("EventView", [props, a]);
    }, []);
    return <div >{props.title}</div>
    return <div style={{whiteSpace: 'nowrap'} }>{props.title}</div>
}
type CalendarEvent = { id: string, resourceId: string, start: Date, end: Date, title: string, type: string, color?: string, classname?: string }
const eventTimeRange: DateRangeFormatFunction = (a, b) => '';
type SelectedLinkBookingType = { booking: CalendarEvent, tournament: IRecord, court: IRecord, mutate: any };
const LinkBookingContext = createContext<[SelectedLinkBookingType | undefined, React.Dispatch<React.SetStateAction<SelectedLinkBookingType | undefined>>] | undefined>(undefined);
const ResourceContext = createContext<any>(undefined);

export default function Resource() {
    const { defaultDate, views } = useMemo(
        () => ({
            defaultDate: new Date(2022, 11, 24),
            views: ['day'] as View[],
        }),
        []
    )

    const app = useModelDrivenApp();
    const { currentRecordId, currentEntityName } = useAppInfo();
    const [selectedBooking, setId] = useContext(LinkBookingContext)!;

    const { addExpand } = useFormChangeHandlerProvider();
    const [data, { onChange }] = useEAVForm(() => ({}));

    useEffect(() => {
        return addExpand('tournamentmatches($expand=match($select=id,name,starttime,endtime,scheduledtime);$select=match,name,id,classid)')
    }, []);

    const info = getRecordSWR(app.getEntity(currentEntityName).collectionSchemaName, currentRecordId, '?$expand=tournamentcourts,tournamentmatches($expand=match($select=id,name,starttime,endtime,scheduledtime,courtid);$select=id,name,match,classid),tournamentclasses($select=name,id)');
    console.log(info);
    const startTime = useStartTime(info);
    const endTime = useEndTime(info);

    const courts = queryEntitySWR(app.getEntityFromKey("Court"),
        {
            $select: 'name,id',
            $filter: `AccountId eq ${info?.record?.centerid} and CourtBookings/any(x: x/starttime ge ${startTime?.toISOString()} and x/endtime le ${endTime?.toISOString()})`,
            $expand: `CourtBookings($filter=starttime ge ${startTime?.toISOString()} and endtime le ${endTime?.toISOString()})`
        }, typeof (info?.record?.centerid) !== "undefined");

    console.log(courts);
    //const bookings = queryEntitySWR(app.getEntityFromKey("Court Booking"),
    //    {
    //        $select: 'starttime,endtime,id',
    //        $expand: 'court($select=name,id)',
    //        $filter: `Court/AccountId eq ${info?.record?.centerid} and starttime ge ${startTime?.toISOString()} and endtime le ${endTime?.toISOString()}`
    //    },
    //    typeof (endTime) !== "undefined" && typeof (startTime) !== "undefined");

    const resourceMap = useMemo(() => {

        if (info?.record?.tournamentcourts) {
            return courts?.data?.items?.filter((c) => 
                info.record.tournamentcourts.some((xx: any) => xx.courtid === c.id)
            ).map(c => ({ resourceId: c.id, resourceTitle: c.name }));
        }

        return courts?.data?.items?.map(c => ({ resourceId: c.id, resourceTitle: c.name }));

    }, [courts?.data?.items?.length]);
    const setMutate = useContext(ResourceContext);
    useEffect(() => { setMutate({ mutate: info.mutate }); }, [info?.record]);


    const matchSlots: MatchSlot[] = useMemo(() => {
        if (info?.record?.tournamentcourts) {

            return info?.record?.tournamentcourts
                .map((x: any) => Array.from(matchgenerator(new Date(x.starttime), new Date(x.endtime), x.courtid,15)))
                .flat()
                .sort((a: any, b: any) => b.start.getTime() - a.start.getTime());
        }
        return [];
    }, [info?.record?.tournamentcourts]);



    const events = useMemo<CalendarEvent[]>(() => {
        console.log(info?.record?.tournamentmatches);

        let bookings= courts?.data?.items?.map(b => b.courtbookings).flat()
            .map(b => ({
                type:"booking",
                id: b.id,
                title: b.name,
                start: new Date(b.starttime),
                end: new Date(b.endtime),
                resourceId: b.courtid
            })) ??[];
        let matches = (info?.record?.tournamentmatches ?? []).map((x: any) => ({
            id: x.id,
            type: "match",
            title: x.name,
            color: colorpalete[info.record.tournamentclasses.findIndex((c: any) => c.id === x.classid)],
            classname: info.record.tournamentclasses.find((c: any) => c.id === x.classid).name,
            start: new Date(x.match.starttime),
            end: new Date(x.match.endtime),
            resourceId: x.match.courtid
        })) as CalendarEvent[];

        let empty = matchSlots?.filter(ms => !matches.some(m => ms.start.getTime() === m.start.getTime() && ms.court === m.resourceId))
            .map(x => ({ type: "empty", title: "empty", start: x.start, end: x.end, resourceId: x.court } as CalendarEvent))??[];

        return bookings.concat(matches).concat(empty);
        //    {
        //        id: 0,
        //        title: 'Kamp 1',
        //        start: new Date(2022, 11, 24, 9, 0, 0),
        //        end: new Date(2022, 11, 24, 13, 0, 0),
        //        resourceId: 1,
        //    },

    }, [courts?.data?.items?.length, info?.record?.tournamentmatches?.length, matchSlots]);
    useEffect(() => { console.log("events", events); }, [events]);

    if (!startTime || !endTime)
        return null;


    return (
        <Fragment>
            <div className="height600">
                <Calendar
                    tooltipAccessor={(e) => e.classname ?? e.title}
                    selected={selectedBooking?.booking}
                    eventPropGetter={(event, start, end, isSelected) => {
                         
                        if (isSelected) {
                            return { style: { backgroundColor: 'black' } }
                        }

                        if (event.color)
                            return { style: { backgroundColor: event.color } };

                        if (event.type === "match") {
                            return { style: { backgroundColor: 'red', width:'75%', left:'25%' } }
                        }
                        if (event.type === "empty") {
                            return { style: { backgroundColor: 'red', width: '75%', left: '25%' } }
                        }
                        if (info.record.tournamentcourts.some((xx: any) => xx.courtid === event.resourceId && new Date(xx.starttime).getTime() === event.start.getTime() && new Date(xx.endtime).getTime() === event.end.getTime())) {
                            return { style: { backgroundColor: 'green' } }
                        }
                        return {}
                    }}
                    formats={{ eventTimeRangeFormat: eventTimeRange }}
                    components={
                        {
                            eventWrapper: EventWrapperView,
                            eventContainerWrapper: EventContainerWrapperView,
                            event: EventView
                        }
                    }
                    selectable={true}
                    enableAutoScroll={true}

                    onSelectEvent={(e, ee) => {
                        console.log("onSelectEvent", [selectedBooking, e, ee]);

                        if (typeof (selectedBooking?.booking.id) !== "undefined" && selectedBooking?.booking.id !== e.id) {
                             
                            let start = selectedBooking.booking.start;
                            let end = selectedBooking.booking.end;
                            let resourceId = selectedBooking.booking.resourceId;

                            selectedBooking.booking.start = e.start;
                            selectedBooking.booking.end = e.end;
                            selectedBooking.booking.resourceId = e.resourceId;

                            e.start = start;
                            e.end = end;
                            e.resourceId = resourceId;
                            setTimeout(() => {
                         
                                onChange((props) => {
                                    console.log("changing", [
                                        e,
                                        ee,
                                        selectedBooking,
                                        props
                                    ]);
                                   
                                    if (selectedBooking.booking.id) {
                                        let obj = props.tournamentmatches.find((x: any) => x.id === selectedBooking.booking.id);
                                        obj.match.starttime = selectedBooking.booking.start.toISOString();
                                        obj.match.endtime = selectedBooking.booking.end.toISOString();
                                        obj.match.courtid = selectedBooking.booking.resourceId;
                                    }

                                    if (e.id) {
                                        let obj = props.tournamentmatches.find((x: any) => x.id === e.id);
                                        obj.match.starttime = e.start.toISOString();
                                        obj.match.endtime = e.end.toISOString();
                                        obj.match.courtid = e.resourceId;
                                    }


                                });
                            }, 0);

                            setId(undefined);
                        } else {
                             
                            setId(selectedBooking?.booking.id === e.id ? undefined : {
                                mutate: info.mutate,
                                booking: e,
                                tournament: info.record,
                                court: courts.data.items.find(c => c.id === e.resourceId)!
                            });
                        }
                    }}
                    onSelectSlot={(e) => console.log("onSelectSlot", e)}
                    onSelecting={(e) => { console.log("onSelecting", e); return true }}
                    defaultDate={startTime}
                    defaultView={Views.DAY}
                    events={events}
                    localizer={localizer}
                    resourceIdAccessor="resourceId"
                    resources={resourceMap}
                    
                    resourceTitleAccessor="resourceTitle"
                    step={15} min={startTime} max={endTime}

                    views={views}
                />
            </div>
        </Fragment>
    )
}


RegistereControl("ResourceScheduling", Resource);
type MatchSlot = {
    start: Date,
    end: Date,
    court: string
}
function* matchgenerator(start: Date, end: Date, court: string, min: number) {
    const count = Math.floor((end.getTime() - start.getTime()) / min / 60000);

    for (let j = 0; j < count; j++)
        yield { start: new Date(start.getTime() + j * min * 60000), end: new Date(start.getTime() + (j + 1) * min * 60000), court } as MatchSlot
}
function playeroverlap(a: matchType, b: matchType) {
    return new Set([a.teama, a.teamb, b.teama, b.teamb].flat()).size !== 8;
}
RegistereRibbonButton("CUSTOM_SCHEDULE_MATCHES_FOR_CLASS", ({ key, ...props }) => {

    const { registerButton, events } = useRibbon();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const { currentEntityName, currentRecordId } = useAppInfo();
    const app = useModelDrivenApp();
   // const info = getRecordSWR(app.getEntity(currentEntityName).collectionSchemaName, currentRecordId, '?$expand=tournamentcourts');

    const [mutate, setMutateState] = useState<any>();
    const Context = useCallback(({ children }: { children: React.ReactNode }) => <ResourceContext.Provider value={setMutateState}>{children}</ResourceContext.Provider>, []);
    useEffect(() => { console.log("mutate", mutate); },[mutate])
    const [profile, setProfile] = useState<string>();
   
   
    const classes = queryEntitySWR(app.getEntityFromKey("Tournament Class"),
        {
            $filter: `tournamentid eq ${currentRecordId}`,
            $expand: 'type($select=name,id)'
        });

    const columns: TableColumnDefinition<IRecord>[] = React.useMemo(
        () => [
            createTableColumn<IRecord>({
                columnId: 'name',
            }),
            createTableColumn<IRecord>({
                columnId: 'type',
            })
        ],
        [],
    );
    const {
        getRows,
        selection: { allRowsSelected, someRowsSelected, toggleAllRows, toggleRow, isRowSelected, selectedRows },
    } = useTableFeatures(
        {
            columns,
            items: classes?.data?.items?.filter(x => typeof (x.type?.name) !== "undefined").map(x => ({ id: x.id, name: x.name, type: x.type.name })) ?? []
        },
        [
            useTableSelection({
                selectionMode: 'multiselect',
                defaultSelectedItems: new Set([]),
            }),
        ],
    );

    const rows = getRows((row) => {
        const selected = isRowSelected(row.rowId);
        return {
            ...row,
            onClick: (e: React.MouseEvent) => toggleRow(e, row.rowId),
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === ' ' || e.key === 'Enter') {
                    toggleRow(e, row.rowId);
                }
            },
            selected,
            appearance: selected ? ('brand' as const) : ('none' as const)
        };
    });

    const keyboardNavAttr = useArrowNavigationGroup({
        axis: 'grid'
    });

    const onGenerate = useCallback(() => {

       // const selected = rows.filter(c => c.selected).map(c => c.item);
        //console.log("Generate", matchSlots, selected);
        //const slots = matchSlots.slice();
        //let matchcount = 1;
        //var matches = [];
        //var rounds = {} as { [key: string]: Array<Array<{ title: string, template: matchType, slot: { start: Date, end: Date } }>> };
        //var template = {
        //    [12]: americano12,
        //    [8]: americano8
        //} as {
        //    [key: number]: matchType[][]
        //};

        //for (let roundid = 0; roundid < 7; roundid++) {

        //    for (let _class of selected) {

        //        rounds[_class.name!] = rounds[_class.name!] ?? [];
        //        rounds[_class.name!][roundid] = [];
        //        let prior = rounds[_class.name!].flat();

        //        let size = parseInt(/Americano.*-(.*)Personer/.exec(_class.type)?.[1]?.trim() ?? '0');
        //        if (size) {
        //            let matchPerRound = size / 4;

        //            for (let matchid = 0; matchid < matchPerRound; matchid++, matchcount++) {
        //                let slot = slots.pop();
        //                let templatematch = template[size][roundid][matchid];
        //                let match = { title: "Kamp " + matchcount, slot, classid: _class.id, template: templatematch };

        //                let other = prior.find(x => x.slot.start.getTime() === slot.start.getTime() && x.slot.end.getTime() === slot.end.getTime() && playeroverlap(x.template, templatematch));
        //                if (other) {
        //                    console.log("FOUND OTHER", [other, match]);

        //                }
        //                rounds[_class.name!][roundid].push(match);
        //                matches.push(match);

        //            }




        //        }

        //    }
        //}
        //console.log(rounds);

        { 
        let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/${app.getEntity(currentEntityName).collectionSchemaName}/records/${currentRecordId}/workflows/14724199-61a0-4f31-801e-29e7d6964a59/runs`, {
            method: "POST",
            body: JSON.stringify({
                classes: rows.filter(c => c.selected).map(c => c.item.id),
                profile
            }),
            credentials: "include"
        });
    }
      //  contextState[1](matches);
        //return;
        //let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/tournaments/records/${currentRecordId}`, {
        //    method: "PATCH",
           
        //    body: JSON.stringify({
        //        tournamentmatches:
        //            matches.map(x => ({
        //                name: x.title,
        //                classid: x.classid,
        //                match: { name: `${info.record.name} - ${x.title}`, scheduledtime: x.slot.start, starttime: x.slot.start, endtime: x.slot.end, courtid: x.slot.court.courtid }
        //            }))
        //    }),
        //    credentials: "include"
        //}).then(rsp => {
        //    rsp.json().then(json => {
        //        console.log("ok");
        //        mutate.mutate();
        //    });
        //});

        console.log("GENERATING");
    }, [rows, mutate]);

    registerButton({
        key: key,
        text: "Schedule Matches",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Schedule Matches",
        disabled: false,
        visible: true,
        onClick: toggleHideDialog


        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);

   

    return ([
        <Dialog open={!hideDialog}><DialogSurface><DialogBody> <DialogTitle>Klasser</DialogTitle>
            <DialogContent>
                <Dropdown onOptionSelect={(e, o) => setProfile(o.optionValue)} placeholder="Vælg Scheduling option">
                    <Option value={'0'} text="Profile 1">Profile 1</Option>
                    <Option value={'1'} text="Profile 2">Profile 2</Option>
                </Dropdown>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableSelectionCell
                                checked={allRowsSelected ? true : someRowsSelected ? 'mixed' : false}
                                onClick={toggleAllRows}
                            />
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Type</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody {...keyboardNavAttr}>
                        {rows.map(({ item, selected, onClick, onKeyDown, appearance, rowId }) => (
                            <TableRow
                                key={item.id}
                                onClick={onClick}
                                onKeyDown={onKeyDown}
                                aria-selected={selected}
                                appearance={appearance}
                            >
                                <TableSelectionCell tabIndex={0} checkboxIndicator={{ tabIndex: -1 }} checked={selected} />
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.type}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </DialogContent>
            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary" onClick={toggleHideDialog}>Close</Button>
                </DialogTrigger>
                <Button appearance="primary" onClick={onGenerate}>Generate</Button>
            </DialogActions>
        </DialogBody></DialogSurface>
        </Dialog>
        , Context]) as React.ReactNode;
});

const LinkContext = ({ children }:any) => {
    const [selectedBooking, setId] = useState<{ booking: CalendarEvent, tournament: IRecord, court: IRecord, mutate: any }>();
    console.log("Selectedbooking1", selectedBooking);
    return <LinkBookingContext.Provider value={[selectedBooking, setId]}>{children}</LinkBookingContext.Provider>;
}
const LinkBooking: React.FC = (props: any) => {
    
    const { registerButton, events } = useRibbon();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const [selectedBooking, a] = useContext(LinkBookingContext)!;
   
   
    registerButton({
        key: "CUSTOM_LINK_BOOKING_TO_TOURNAMNET",
        text: "Add to tournament",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Add to tournament",
        disabled: false, visible: typeof (selectedBooking) !== "undefined",
        onClick: () => {
            console.log("hello world", selectedBooking);

            let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/tournamentcourts/records`, {
                method: "POST",
                body: JSON.stringify({
                    name: selectedBooking?.court.name,
                    courtid: selectedBooking?.court.id,
                    starttime: selectedBooking?.booking.start,
                    endtime: selectedBooking?.booking.end,
                    tournamentid: selectedBooking?.tournament.id


                }),
                credentials: "include"
            }).then(rsp => {
                rsp.json().then(json => {
                    selectedBooking?.mutate();
                });
            });

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog, selectedBooking]);
    return null;
}
RegistereRibbonButton("CUSTOM_LINK_BOOKING_TO_TOURNAMNET", ({ key, ...props }) => {
  
    return [LinkBooking, LinkContext] as React.ReactNode;
})