import { ContentBlock, ContentState, EditorState } from "draft-js";
import { Block } from "./constants";
import { getCurrentBlock } from "./getCurrentBlock";
import { getDefaultBlockData } from "./getDefaultBlockData";

/*
Adds a new block (currently replaces an empty block) at the current cursor position
of the given `newType`.
*/
export const addNewBlock = (editorState: EditorState, newType = Block.UNSTYLED, initialData = {}) => {
    const selectionState = editorState.getSelection();
    if (!selectionState.isCollapsed()) {
        return editorState;
    }
    const contentState = editorState.getCurrentContent();
    const key = selectionState.getStartKey();
    const blockMap = contentState.getBlockMap();
    const currentBlock = getCurrentBlock(editorState);
    if (!currentBlock) {
        return editorState;
    }
    if (currentBlock.getLength() === 0) {
        if (currentBlock.getType() === newType) {
            return editorState;
        }
        const newBlock = currentBlock.merge({
            type: newType,
            data: getDefaultBlockData(newType, initialData),
        }) as ContentBlock;
        const newContentState = contentState.merge({
            blockMap: blockMap.set(key, newBlock),
            selectionAfter: selectionState,
        }) as ContentState;
        return EditorState.push(editorState, newContentState, 'change-block-type');
    }
    return editorState;
};