import * as React from 'react';
import { makeResetStyles, makeStyles, mergeClasses } from '@griffel/react';
import { createFocusOutlineStyle } from '@fluentui/react-tabster';
import { drawerCSSVars, drawerDefaultStyles, useDrawerBaseClassNames } from '../../shared/useDrawerBaseStyles.styles';
export const overlayDrawerClassNames = {
    root: 'fui-OverlayDrawer',
    backdrop: 'fui-OverlayDrawer__backdrop'
};
/**
 * Styles for the root slot
 */ const useDrawerResetStyles = makeResetStyles({
    ...createFocusOutlineStyle(),
    ...drawerDefaultStyles,
    position: 'fixed',
    top: 0,
    bottom: 0
});
const useDrawerRootStyles = makeStyles({
    /* Positioning */ start: {},
    end: {},
    bottom: {
        top: 'auto',
        height: `var(${drawerCSSVars.drawerSizeVar})`,
        width: '100vw'
    }
});
/**
 * Apply styling to the OverlayDrawer slots based on the state
 */ export const useOverlayDrawerStyles_unstable = (state)=>{
    'use no memo';
    const baseClassNames = useDrawerBaseClassNames(state);
    const resetStyles = useDrawerResetStyles();
    const rootStyles = useDrawerRootStyles();
    const backdrop = state.root.backdrop;
    state.root.className = mergeClasses(overlayDrawerClassNames.root, baseClassNames, resetStyles, rootStyles[state.position], state.root.className);
    if (backdrop) {
        backdrop.className = mergeClasses(overlayDrawerClassNames.backdrop, backdrop.className);
    }
    return state;
};
