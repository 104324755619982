
import * as React from "react";
import {
    FontIncrease24Regular,
    FontDecrease24Regular,
    TextFont24Regular,
    Add24Regular,
} from "@fluentui/react-icons";
import { Toolbar, ToolbarButton, ToolbarDivider, mergeClasses } from "@fluentui/react-components";
import type { ToolbarProps } from "@fluentui/react-components";
import { useSectionStyles } from "@eavfw/apps";
import { Flex } from "@fluentui/react-migration-v0-v9";
import { ViewHeaderBox } from "./Components/ViewHeaderBox";
import { MultipleCards } from "./Components/TablesCarousel";

const TablesToobar = ({ className, ...props }: ToolbarProps) => {

    const sectionStyles = useSectionStyles();
    return <Toolbar
        aria-label="Large Toolbar"
        className={mergeClasses(sectionStyles.section, className)}
        
        {...props}
        style={{ padding:"8px"} }
        size="large"
       
    >
        <ViewHeaderBox label="Tables" >
            Create and manage tables
        </ViewHeaderBox>
        <ToolbarDivider />
        <ToolbarButton
            aria-label="New table"
            appearance="primary"
            icon={<Add24Regular />}
        >Add table</ToolbarButton>

        <ToolbarButton aria-label="Reset Font Size" icon={<TextFont24Regular />} />
    </Toolbar>
}

export const TablesView = () => {
    const sectionStyles = useSectionStyles();
    return <div style={{ display: "flex", flexDirection: "column", width:"100%" }}>
        <TablesToobar />
        <div className={sectionStyles.section}>
            <MultipleCards/>
        </div>

    </div>
}