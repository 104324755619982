import { RegistereRibbonButton, useAppInfo, useModelDrivenApp, useRibbon, SimpleDialog, useFormChangeHandlerProvider } from "@eavfw/apps";

import { useBoolean } from "@fluentui/react-hooks";
import dynamic from 'next/dynamic'
import { Suspense, useCallback } from 'react'

import { Dropdown, Option } from '@fluentui/react-components';
import { useState } from "react";
import { queryEntitySWR, useJsonFetcher } from "@eavfw/manifest";
import { CompoundButton, Input, Label, makeStyles, shorthands, useId } from "@fluentui/react-components";
import { PlugDisconnected24Regular } from "@fluentui/react-icons";
import { publicDecrypt } from "crypto";


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('20px'),
        maxWidth: '400px',
        // Stack the label above the field (with 2px gap per the design system)
        '> div': {
            display: 'flex',
            flexDirection: 'column',
            ...shorthands.gap('2px')
        }
    }
});

//const AiiaConnectComponent: React.FC = () => {

//    const clientidid = useId('input-clientid');
//    const secretid = useId('input-secret');
//    const [clientid, setClientId] = useState<string>();
//    const [secret, setSecret] = useState<string>();
//    const { mutate: mutateForm } = useFormChangeHandlerProvider();
//    const [baseUrl] = useJsonFetcher();
//    const { currentEntityName, currentRecordId } = useAppInfo();
//    const connect = useCallback(() => {
//        fetch(`${baseUrl}/providers/PaymentProviders.Aiia/connect?accountid=${currentRecordId}`, {
//            credentials: "include",
//            method: "POST",
//            headers: {"contentType":"application/json" },
//            body: JSON.stringify({ secret, clientid })
//        })
//            .then(() => {
//                setTimeout(() => {
//                    mutateForm();
//                }, 2500);
//            });
//    }, [secret, clientid]);

//    const styles = useStyles();

//    return <div className={styles.root}>
//        <div>
//            <Label htmlFor={clientidid}>ClientId</Label>
//            <Input type="text" id={clientidid} onChange={(ev, data) => setClientId(data.value)} />
//        </div>
//        <div>
//            <Label htmlFor={secretid}>Password input</Label>
//            <Input type="password"   id={secretid} onChange={(ev, data) => setSecret(data.value)} />
//        </div>

//        <CompoundButton
//            icon={<PlugDisconnected24Regular />}
//            secondaryContent="Connect Aiia" onClick={connect}
//        >
//            Connect
//        </CompoundButton>
//    </div>
//}

export type ConnectProviderComponentProps = {
    provider: string
    displayName: string
}
export const ConnectProviderComponent: React.FC<ConnectProviderComponentProps> = ({ provider ,displayName}) => {
    const { currentEntityName, currentRecordId } = useAppInfo();
    const [environment, setEnvironment] = useState("sandbox");
    const [baseUrl] = useJsonFetcher();

    const connect = useCallback(() => {
        window.open(`${baseUrl}/providers/${provider}/connect?accountid=${currentRecordId}&redirecturi=${encodeURIComponent(location.protocol + "//" + location.host + location.pathname + location.search)}&environment=${environment}`, '_blank');
    }, [provider])
    


    return <>
        <Dropdown placeholder="Environment..." onOptionSelect={(e: any, data: any) => { console.log(data); console.log(e); if (data.optionValue) { setEnvironment(data.optionValue); } }} defaultValue={environment} >
            <Option>Sandbox</Option>
            <Option>Production</Option>
        </Dropdown>



        <CompoundButton  
            icon={<PlugDisconnected24Regular />}
            secondaryContent={`Connect ${displayName}`} onClick={ connect}
        >
            Connect
        </CompoundButton>
    </>
}

//const MobilePayConnectComponent: React.FC = () => {
//    const { currentEntityName, currentRecordId } = useAppInfo();
//    const [environment, setEnvironment] = useState("sandbox");

    


//    return <>
//        <Dropdown placeholder="Environment..." onOptionSelect={(e: any, data: any) => { console.log(data); console.log(e); if (data.optionValue) { setEnvironment(data.optionValue); } }} defaultValue={environment} >
//            <Option>Sandbox</Option>
//            <Option>Production</Option>
//        </Dropdown>



//        <CompoundButton href={`${process.env['NEXT_PUBLIC_BASE_URL']?.slice(0, -1)}/providers/PaymentProviders.MobilePay?accountid=${currentRecordId}&redirecturi=${encodeURIComponent(location.protocol + "//" + location.host + location.pathname + location.search)}&environment=${environment}`}
//            icon={<PlugDisconnected24Regular />}
//            secondaryContent="Connect Mobile Pay"  
//        >
//            Connect
//        </CompoundButton>
//    </>
//}

RegistereRibbonButton("CUSTOM_MOBILEPAY_AUTHORIZE", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();


    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const app = useModelDrivenApp();

    registerButton({
        key: key,
        text: "Connect Payment Provider",
        iconProps: props.iconProps ?? { iconName: 'Send' },
        title: props.title ?? props.text ?? "Connect Payment Provider",
        disabled: false,
        onClick: () => {
            toggleHideDialog();

        }
        //workflow: props.workflow
        // onClick: onClick
    }, [toggleHideDialog]);

    const { data: providers } = queryEntitySWR(app.getEntityFromKey("Payment Provider Type"), { '$select': 'type, id' });
    const [selectedProvider, setSelectedProvider] = useState();

    return <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog}>

        <Dropdown placeholder="Provider" onOptionSelect={(e: any, data: any) => { if (data.optionValue) { setSelectedProvider(data.optionValue); } }} >
            {providers?.items.map(o => <Option key={o.id} value={o.type} >{o.type}</Option>)}
        </Dropdown>

        <hr />

        {selectedProvider === "Aiia Pay" && <ConnectProviderComponent displayName={selectedProvider} provider="PaymentProviders.Aiia" />}
        {selectedProvider === "MobilePay Subscriptions" && <ConnectProviderComponent displayName={selectedProvider} provider="PaymentProviders.MobilePay" />}

    </SimpleDialog>

});