import { makeStyles, mergeClasses } from '@griffel/react';
import { navItemTokens } from '../sharedNavStyles.styles';
export const navDrawerClassNames = {
    root: 'fui-NavDrawer'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        width: '260px',
        backgroundColor: navItemTokens.backgroundColor,
        alignItems: 'unset'
    }
});
/**
 * Apply styling to the NavDrawer slots based on the state
 */ export const useNavDrawerStyles_unstable = (state)=>{
    'use no memo';
    const styles = useStyles();
    state.root.className = mergeClasses(navDrawerClassNames.root, styles.root, state.root.className);
    return state;
};
