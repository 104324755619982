import { makeResetStyles, makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const messageBarActionsClassNames = {
    root: 'fui-MessageBarActions',
    containerAction: 'fui-MessageBarActions__containerAction'
};
/**
 * Styles for the root slot
 */ const useRootBaseStyles = makeResetStyles({
    ...shorthands.gridArea('secondaryActions'),
    display: 'flex',
    columnGap: tokens.spacingHorizontalM,
    paddingRight: tokens.spacingHorizontalM
});
const useContainerActionBaseStyles = makeResetStyles({
    ...shorthands.gridArea('actions'),
    paddingRight: tokens.spacingHorizontalM
});
const useMultilineStyles = makeStyles({
    root: {
        justifyContent: 'end',
        marginTop: tokens.spacingVerticalMNudge,
        marginBottom: tokens.spacingVerticalS,
        marginRight: '0px',
        paddingRight: tokens.spacingVerticalM
    }
});
/**
 * Apply styling to the MessageBarActions slots based on the state
 */ export const useMessageBarActionsStyles_unstable = (state)=>{
    const rootBaseStyles = useRootBaseStyles();
    const containerActionBaseStyles = useContainerActionBaseStyles();
    const multilineStyles = useMultilineStyles();
    state.root.className = mergeClasses(messageBarActionsClassNames.root, rootBaseStyles, state.layout === 'multiline' && multilineStyles.root, state.root.className);
    if (state.containerAction) {
        state.containerAction.className = mergeClasses(messageBarActionsClassNames.containerAction, containerActionBaseStyles, state.containerAction.className);
    }
    return state;
};
