import { createTheme } from "@fluentui/react";
import { RegisterFeature } from "@eavfw/apps";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";

const defaultTheme = createTheme({
    semanticColors: {
        actionLink: '#4a9c35'
    },
    palette: {
        themePrimary: '#ba530f',
        themeLighterAlt: '#fcf7f3',
        themeLighter: '#f4ded0',
        themeLight: '#eac4aa',
        themeTertiary: '#d68f60',
        themeSecondary: '#c26425',
        themeDarkAlt: '#a84b0d',
        themeDark: '#8d3f0b',
        themeDarker: '#682f08',
        neutralLighterAlt: '#f3f0ee',
        neutralLighter: '#efecea',
        neutralLight: '#e5e3e1',
        neutralQuaternaryAlt: '#d6d3d1',
        neutralQuaternary: '#ccc9c8',
        neutralTertiaryAlt: '#c4c2c0',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#faf7f5',
    }
});


const topBarTheme = createTheme({
    palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#203157',
        neutralLighter: '#25375d',
        neutralLight: '#2e4069',
        neutralQuaternaryAlt: '#33466f',
        neutralQuaternary: '#384c75',
        neutralTertiaryAlt: '#50638c',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#094080',
    }
});


export default defaultTheme;
let v9T = createV9Theme(defaultTheme);

RegisterFeature("defaultTheme", defaultTheme);
RegisterFeature("topBarTheme", topBarTheme);
RegisterFeature("defaultV2Theme", v9T);
RegisterFeature("topBarV2Theme", createV9Theme(topBarTheme));
