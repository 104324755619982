
import { RegistereRibbonButton, SimpleDialog, useAppInfo, useModelDrivenApp, useRibbon } from "@eavfw/apps";
import { DefaultButton, DialogFooter, PrimaryButton } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ChangeEventHandler, useCallback, useState } from "react";
import { Input, ProgressBar, Field } from "@fluentui/react-components";
 


RegistereRibbonButton("IMPORT_RECORDS", ({ key, ...props }) => {
    const { registerButton, events } = useRibbon();
    const { currentEntityName, currentRecordId } = useAppInfo();
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const app = useModelDrivenApp();

    registerButton({
        key: key,
        text: props.text ?? "Import",
        iconProps: props.iconProps ?? { iconName: 'Import' },
        title: props.title ?? props.text ?? "Import",
        disabled: false,
        onClick: toggleHideDialog
        //workflow: props.workflow
        // onClick: onClick
    });
    const [value, setValue] = useState<string>();
    const startWorkflow = useCallback(() => {

        let rsp = fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/entities/${app.getEntity(currentEntityName).collectionSchemaName}/records/${currentRecordId}/workflows/2b863c8c-e491-4c9b-8344-488af020a6fc/runs`, {
            method: "POST",
            body: JSON.stringify({
                 file:value
            }),
            credentials: "include"
        });
    }, [currentRecordId,value])
    const fileAdded = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
        let input = e.target;
        var reader = new FileReader();

        reader.onload = function (e) {
            console.log(e);
            console.log(e.target?.result);
            setValue(e.target?.result as string);

        }
        if (input.files) {
            reader.readAsDataURL(Array.from(input.files)[0]);
        }
    }, []);
    return (
        <SimpleDialog hideDialog={hideDialog} toggleHideDialog={toggleHideDialog} maxWidth="90vw" >
            <label>
                SMS Template
            </label>
            <Field
               
                validationState="none"
            >
                <ProgressBar max={100} value={10} />
            </Field>
            <Field
                
                validationState="none"
            >
                <input type="file" onChange={ fileAdded} />
            </Field>
            <DialogFooter>
                <PrimaryButton onClick={startWorkflow}>OK</PrimaryButton>
                <DefaultButton onClick={() => { }}>Cancel</DefaultButton>
            </DialogFooter>
        </SimpleDialog>
    )
});