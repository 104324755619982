import { makeStyles, mergeClasses } from '@griffel/react';
import { useDrawerHeaderStyles_unstable } from '@fluentui/react-drawer';
export const navDrawerHeaderClassNames = {
    root: 'fui-NavDrawerHeader'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        margin: 'unset',
        paddingInlineStart: '14px',
        paddingBlock: '5px'
    }
});
/**
 * Apply styling to the NavDrawerHeader slots based on the state
 */ export const useNavDrawerHeaderStyles_unstable = (state)=>{
    'use no memo';
    useDrawerHeaderStyles_unstable(state);
    const styles = useStyles();
    state.root.className = mergeClasses(navDrawerHeaderClassNames.root, styles.root, state.root.className);
    return state;
};
