

import "./Ribbons";
export * from "./CustomControlDemo";
import "./Apps/CustomerEnvironmentDesigner";
import { PageDesignEditor } from "@eavfw/designer";
import { RegistereControl } from "@eavfw/apps";

import "@eavfw/rich-document-editor";
import "@eavfw/rich-text-editor";
import "./Tasks/KanbanBoard";
import "./Events/EventSchedule";
import "./Events/StatsRecorder";
import "./Budget";
import "./Communication/SMSCommunicationOverview";

import "@eavfw/task-management";
import "@eavfw/quickform-designer";
import "@eavfw/quickform-core/src/components/question/input-types";
import "@eavfw/quickform-input-select";
import "@eavfw/quickform-input-select/src/designer";

export * from "@eavfw/codeeditor";

RegistereControl("PageDesignEditor", PageDesignEditor);

import "./CMS";
import { RegisterFeature } from "@eavfw/apps";
 
RegisterFeature("WizardExpressionsProvider", (formValues: any) => {
     
    return {
      
    }
});