


import { EditorState } from 'draft-js';
import React, { useCallback } from 'react';


import styles from "../AddButton/AddButton.module.scss";
import { Block } from '../Utils/constants';
import { addNewBlock } from "../Utils/addNewBlock"
import { FontIcon, mergeStyles } from '@fluentui/react';

 

export type ImageButtonProps = {
    close: Function;
    updateEditor: (cb: (state: EditorState) => EditorState) => void;
}
export const ImageButton: React.FC<ImageButtonProps> = ({ close, updateEditor }) => {


    const onClick = useCallback(() => {

        updateEditor((state) => {
            return addNewBlock(state, Block.IMAGE);
        });


    }, []);



    return <button className={styles.mdSBButton} onClick={onClick} type="button">
        <FontIcon aria-label="PictureFill" iconName="PictureFill"   />
    </button>


}

