import {
    Body1,
    Button,
    Caption1,
    Card,
    CardHeader,
    makeStyles,
    tokens,
    typographyStyles,
} from "@fluentui/react-components";
import {
    Carousel,
    CarouselButton,
    CarouselCard,
    CarouselNav,
    CarouselNavImageButton,
    CarouselSlider,
} from "@fluentui/react-carousel-preview";
import * as React from "react";
import { useCustomerManifest } from "../../Contexts/CustomerManifestDefinitionProvider";

const SWAP_IMAGE =
    "https://fabricweb.azureedge.net/fabric-website/assets/images/wireframe/image-square.png";
const useClasses = makeStyles({
    card: {
        flex: "0 0 45%",
        margin: "0px 10px",
    },
    test: {
        ...typographyStyles.largeTitle,
        alignContent: "center",
        borderRadius: tokens.borderRadiusLarge,
        height: "200px",
        textAlign: "center",
    },
});

const TestComponent: React.FC<{ accentColor: string; children: string }> = (
    props
) => {
    const { accentColor, children } = props;
    const classes = useClasses();

    return (
        <div className={classes.test} style={{ backgroundColor: accentColor }}>
            {children}
        </div>
    );
};
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
const TableCard = ({ name }: { name: string }) => {
    return <Card>
        <CardHeader header={
            <Body1>
                <b>{name}</b>
            </Body1>
        }
            description={<Caption1>Developer</Caption1>}
            action={
                <Button
                    appearance="transparent"
                    icon={<MoreHorizontal20Regular />}
                    aria-label="Edit"
                />
            }>
        </CardHeader>
    </Card>
}

export const MultipleCards = () => {
    const classes = useClasses();

    const { entities } = useCustomerManifest();

    return (
        <Carousel>
            <CarouselSlider>
                {Object.entries(entities).map(([entityKey, entity]) => <TableCard name={entity.displayName ?? entityKey} key={entityKey} />)}
               
            </CarouselSlider>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <CarouselButton navType="prev" />
                <CarouselNav>
                    {() => <CarouselNavImageButton image={{ src: SWAP_IMAGE }} />}
                </CarouselNav>
                <CarouselButton navType="next" />
            </div>
        </Carousel>
    );
};